<template lang="pug">
    .imprimir-transacao

        Dialog.dialog-opcoes-impressao(header="Opções de impressão" :modal="true" :visible.sync="dialogOpcoesImpressao.visible")
            .ta-center.my-2
                ProgressSpinner.waitingImprimir(v-if='dialogOpcoesImpressao.waiting' strokeWidth="6")
                div(v-else)
                    Button.p-button-info.mr-2(label='Impressão térmica' icon='jam jam-ticket' @click='imprimirGuias(true)')
                    Button.p-button-success(label='Impressão A4' icon='jam jam-printer' @click='imprimirGuias()')
        Dialog.dialog-opcoes-impressao(header="Opções de impressão" :modal="true" :visible.sync="dialogImprimirEstorno.visible")
            .ta-center.my-2
                ProgressSpinner.waitingImprimir(v-if='dialogImprimirEstorno.waiting' strokeWidth="6")
                div(v-else)
                    Button.p-button-info.mr-2(label='Impressão térmica' icon='jam jam-ticket' @click='imprimirTermicaEstorno(true)')
                    Button.p-button-success(label='Impressão A5' icon='jam jam-printer' @click='imprimirTermicaEstorno()')

        Dialog.dialogRemover(id="dialog-remove" header='Apagar movimento' :modal='true' :visible.sync='dialogRemover')
            .p-grid.p-fluid
                .p-col-6
                    label.form-label Método:
                    InputText.readonly(v-model='movimento.ds_forma_pagamento' :readonly='true')
                .p-col-6
                    label.form-label Valor:
                    InputText.readonly(v-model='movimento.nr_valor_f' :readonly='true')
                .p-col-12
                    label.form-label Data:
                    Calendar(v-model='movimento.dt_movimento' dateFormat="dd/mm/yy" :locale="ptbr" :manualInput='false' appendTo="dialog-remove" style="display: flex, justify-content: start, align-center: start")
                .p-col-12
                    label.form-label Motivo:
                    Textarea(v-model='movimento.ds_motivo' rows='4' cols='30')
                .p-col-12.ta-center
                    ProgressSpinner.waitingApagar(v-if='waitingApagar' strokeWidth="2")
                    Button(v-else label='Apagar' icon='jam jam-minus' @click='cancelarMovimento()')

        Dialog.dialogAdicionar(header='Adicionar forma de pagamento' :modal='true' :visible.sync='dialogNovoPagamento')
          .p-grid.p-fluid
            .p-col-6
                label.form-label Método:
                Dropdown(
                    v-model='movimento.cd_forma_pagamento'
                    :options='options.formasPagamentoMovimento'
                    optionLabel='text'
                    optionValue='value'
                    dataKey='value'
                )

            .p-col-6
                label.form-label Valor:
                CurrencyInput(
                    v-model='movimento.nr_valor'
                    locale='pt-BR'
                    :auto-decimal-mode='true'
                    )

            .p-col-6(v-if='movimento.cd_forma_pagamento == 5 || movimento.cd_forma_pagamento == 6 || movimento.cd_forma_pagamento == 3')
              label.form-label Operadora:
              Dropdown(
              v-model='movimento.cd_operadora'
              :options='options.operadoras'
              optionLabel='ds_operadora'
              optionValue='id'
              dataKey='id'
              )
            .p-col-6(v-if='movimento.cd_forma_pagamento == 5 || movimento.cd_forma_pagamento == 6')
              label.form-label Bandeira:
              Dropdown(
              :disabled='!movimento.cd_operadora'
              v-model='movimento.ie_bandeira'
              :options='options.bandeiras[movimento.cd_operadora]'
              optionLabel='ds_valor'
              optionValue='ie_valor'
              dataKey='ie_valor'
              )
            .p-col-6(v-if='movimento.cd_forma_pagamento == 5 || movimento.cd_forma_pagamento == 6')
              label.form-label Últimos 4 dígitos do cartão:
              InputText(v-model='movimento.nr_final_cartao' :useGrouping='false')

            .p-col-6(v-if='movimento.cd_forma_pagamento == 5 || movimento.cd_forma_pagamento == 6')
              label.form-label Código de autorização:
              InputText(v-model='movimento.cd_autorizacao'  :useGrouping='false')

            .p-col-6(v-if='movimento.cd_forma_pagamento == 3')
              label.form-label DOC :
              InputText(v-model='movimento.nr_pix_doc'  :useGrouping='false')

            .p-col-6(v-if='movimento.cd_forma_pagamento == 5 && !model.ie_cirurgia')
                label.form-label Parcelas:
                Dropdown(
                    v-model='movimento.nr_credito_parcelas'
                    :options='ie_cobrar_cliente ? options.parcelas : options.parcelasCredishop '
                    :disabled='(model.nr_valor == 0 || movimento.ie_bandeira == null)'
                    optionLabel='text'
                    optionValue='value'
                    dataKey='value'
                    class='parcelas'
                )
                  template(#option='props')
                    div.p-dropdown-item
                        span <b> {{props.option.text}}</b><br>
                        span {{formatPrice(props.option.valor_total)}}

            .p-col-2(v-if='movimento.cd_forma_pagamento == 5 && model.ie_cirurgia')
                label.form-label Parcelas:
                Dropdown(
                    v-model='movimento.nr_credito_parcelas'
                    :options='ie_cobrar_cliente ? options.parcelas : options.parcelasCredishop '
                    :disabled='(movimento.nr_valor == 0 || movimento.ie_bandeira == null)'
                    optionLabel='value'
                    optionValue='value'
                    dataKey='value'
                    class='parcelas'
                )

            .p-col-5(v-if='movimento.cd_forma_pagamento == 5 && model.ie_cirurgia')
                label.form-label Juros:
                CurrencyInput(
                    v-model='movimento.nr_valor_juros'
                    locale='pt-BR'
                    :auto-decimal-mode='true'
                    )
            .p-col-5(v-if='movimento.cd_forma_pagamento == 5 && model.ie_cirurgia')
                label.form-label Total:
                CurrencyInput(
                    v-model='total_cirurgia'
                    locale='pt-BR'
                    disabled
                    :class="{ 'valor-negativo': total_cirurgia < 0 }"
                    :auto-decimal-mode='true'
                    )
            .p-col-12.ta-center
                ProgressSpinner.waitingAdicionar(v-if='waitingAdicionar' strokeWidth="2")
                Button(v-else label='Adicionar' icon='jam jam-plus' @click='addNovoPagamento()')

        Dialog.dialogUsuario(header='Confirme o estorno' :modal='true' :visible.sync='dialogUsuario' )
            .p-grid.p-fluid(style='width: 300px; overflow-y: visible')

                .p-col-12
                    label.form-label Usuário:
                    InputText(type='text' v-model='usuario.username')

                .p-col-12
                    label.form-label Senha:
                    InputText(type='password' v-model='usuario.password')
                .p-col-12.ta-center
                    ProgressSpinner.waitingAdicionar(v-if='waintingUsuario' strokeWidth="2")
                    Button(v-else label='Confirmar' @click='confirmarUsuario()')

        ConfirmDialog(
            :display="salvarAprovar.show"
            @confirmed="salvarEstorno(salvarAprovar.id)"
            @close="salvarAprovar.show = false")
            template(#text-content="props")
                span Deseja mesmo <b>salvar e aprovar</b> o estorno?

        ConfirmDialog(
            :display="dialogDesfazerCancelamento.show"
            @confirmed="desfazCancelamento()"
            @close="dialogDesfazerCancelamento.show = false")
            template(#text-content="props")
                Message(severity="warn" :sticky='true' :closable='false') Ao prosseguir com esta operação, o valor desta guia será descontado do saldo de crédito medclub do cliente. Portanto, é necessário que o pagante possua saldo suficiente.
                span Deseja mesmo <b>desfazer o cancelamento da guia?</b>

        Dialog.dialogRemover(header='Aprovar estorno' :modal='true' :visible.sync='dialogAprovarEstorno')
            .ta-center
                p Deseja aprovar mesmo o estorno <b>{{ estorno.id }}</b>?
                ProgressSpinner.waitingAdicionar(v-if='waitingAprovarEstorno' strokeWidth="2")
                Button(v-else label='Aprovar' icon='jam jam-check' @click.once='realizarPagamentosPendentes(estorno.id)')

        Dialog.dialogAdicionar(header='Adicionar forma de devolução' :modal='true' :visible.sync='dialogNovoEstorno')
            .p-grid.p-fluid

                .p-col-6
                    label.form-label Método:
                    Dropdown(
                        v-model='movimento.cd_forma_pagamento'
                        :options='options.formasPagamento'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                    )
                      //- template(#option="props")
                      //-   .p-dropdown-car-option(v-if="props.option.value != '3' && !supervisao")
                      //-       span {{ props.option.text }}
                      //-   .p-dropdown-car-option.p-disabled(v-else @click.stop="")
                      //-       span {{ props.option.text }}

                .p-col-6
                    label.form-label Valor:
                    CurrencyInput(
                        v-model='searchQuery'
                        locale='pt-BR'
                        @input='isTyping = true'
                        :auto-decimal-mode='true'
                        :valueRange="{ 'min': 0, 'max': restaEstorno }"
                        )
                .p-col-6(v-if='supervisao')
                    label.form-label Situação:
                    Dropdown(

                        v-model='movimento.ie_situacao'
                        :options='options.ie_situacao'
                        optionLabel='label'
                        optionValue='value'
                        dataKey='value'
                    )
                .p-col-6(v-if='movimento.cd_forma_pagamento == 5')
                    label.form-label Juros:
                    CurrencyInput(
                        v-model='juros'
                        locale='pt-BR'
                        disabled
                        :auto-decimal-mode='true'
                        )
                .p-col-12.ta-center
                    ProgressSpinner.waitingAdicionar(v-if='waitingAdicionar' strokeWidth="2")
                    Button(v-else label='Adicionar' icon='jam jam-plus' @click='addNovoEstorno()')


        Dialog.dialogAdicionar(header='Adicionar movimento' :modal='true' :visible.sync='dialogAdicionar')
            .p-grid.p-fluid

                .p-col-6
                    label.form-label Método:
                    Dropdown(
                        v-model='movimento.cd_forma_pagamento'
                        :options='options.formasPagamento'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                        @change='metodoListener()'
                    )

                .p-col-6
                    label.form-label Valor:
                    .p-inputgroup
                        span.p-inputgroup-addon R$
                        span(style='flex: 1')
                            currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                v-model='movimento.nr_valor'
                                locale='pt-BR'
                                :class="{ 'valor-negativo': movimento.nr_valor < 0 }"
                                :auto-decimal-mode='true'
                                @input='fixNrValor()')

                .p-col-12(v-if="exibeSaldo")
                    label.form-label Saldo Pagante:
                    .p-inputgroup
                        span.p-inputgroup-addon R$
                        span(style='flex: 1')
                            currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                v-model='valor_saldoPagante'
                                locale='pt-BR'
                                :disabled="true"
                                :auto-decimal-mode='true'
                                @input='fixNrValor()')

                .p-col-6
                    label.form-label Operação:
                    Dropdown(
                        v-model='movimento.ie_operacao'
                        :options='options.operacao'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                        @input='fixNrValor'
                    )

                .p-col-6
                    label.form-label Data:
                    Calendar(v-model='movimento.dt_movimento' dateFormat="dd/mm/yy" :locale="ptbr" :manualInput='false')

                .p-col-12
                    label.form-label Motivo:
                    Textarea(v-model='movimento.ds_motivo' rows='4' cols='30')

                .p-col-12.ta-center
                    ProgressSpinner.waitingAdicionar(v-if='waitingAdicionar' strokeWidth="2")
                    Button(v-else label='Adicionar' icon='jam jam-plus' @click='adicionar()')

        Dialog.dialogRemover(header='Cancelar estorno' :modal='true' :visible.sync='estornoCancelarConfirm')
            .ta-center
                p Dejes mesmo cancelar o estorno de codigo <b>{{ this.estorno.id }}</b>?
                ProgressSpinner.waitingApagar(v-if='waitingCancelarEstorno' strokeWidth="6")
                Button.p-button-danger(v-else label='Cancelar' icon='jam jam-minus-circle' @click='cancelarEstorno(supervisao ? "removeEstorno" : "removeEstornoOperacional")')
        Dialog.dialogRemover.ta-center(:modal='true' :visible.sync='waitingCancelamento' :showHeader='false' :closable='false' )
          div(style="height: 200px; display: flex; flex-direction: column; justify-content: center, align-items: center ")
            h3 Aguarde...
            ProgressSpinner(strokeWidth='6' :style="{'height':'6em'}")
        Dialog.dialogCancelamentosPendentes(header='Transações Cartões' style='' :modal='true' :closable='true' :visible.sync='dialogEstornosPendentes' @hide='concluirCancelamentoTEF()')
          .ta-center
            ProgressSpinner.waitingImprimir(v-if='waitingEstornosPendentes' strokeWidth="6")
            .ta-center(v-else-if='estornosTEFPendentes.length == 0')
              span Nenhum pagamento pendente.

            .p-grid.p-fluid(v-else)
              .p-col-12
                    DataTable.datatableGuias(:value='estornosTEFPendentes')
                        Column(headerStyle='width:20%' field='cd_forma_pagamento_f' header='Forma de Pagamento' bodyStyle='word-break: break-all; text-align:center')
                        Column(headerStyle='width:20%' field='nm_bandeira' header='Bandeira' bodyStyle='word-break: break-all; text-align:center; padding: 0;')
                        Column(headerStyle='width:15%' field='nr_valor_f' header='Valor')
                        Column(headerStyle='width:15%' field='nr_valor_acrescimo_f' header='Juros')
                        Column(headerStyle='width:15%' field='amount_f' header='Valor total')
                        Column(headerStyle='width:15%' field='ie_situacao' header='Situação' bodyStyle='word-break: break-all; text-align:center; padding: 0, height: 100%')
                            template(#body='props')
                                p.highlight(:class="{\
                                    ex: props.data.ie_situacao === 'PA',\
                                    ca: props.data.ie_situacao === 'CA'\
                                }") {{ props.data.ie_situacao === 'PA' ? 'PAGO' : 'CANCELADO'}}
                        Column(headerStyle='width:10%' header='Ação' bodyStyle='text-align:right')
                            template(#body='props')
                              .ta-center
                                  Button.p-button-raised.p-button-rounded.p-button-danger(v-if='props.data.ie_situacao !== "CA"' v-tooltip.top="'Estornar compra'" icon='pi custom-svg-icon-2' @click='removerFormadePagamento(props)')
                                  Button.p-button-raised.p-button-rounded(v-else v-tooltip.top="'Imprimir comprovante'" icon='jam jam-printer' @click='imprimirComprovanteEstornoTotal(props.data?.comprovanteAdquirente)')
              //- .p-col-12
              //-   .ta-center()
              //-     Button(label='Concluir' style='width: 40%' @click='concluirCancelamentoTEF()') v-if='props.data.ie_situacao !== "CA"'

        Dialog.dialogAdicionarEstorno(:header="`${(estorno && estorno.id) ? 'Editar' : 'Adicionar'} estorno total`" :modal='true' :visible.sync='dialogEstornoTotal')
            .p-grid.p-fluid(style='height: 500px; overflow: scroll')
                .p-col-12
                    Panel
                      template(#header)
                        .p-grid.p-fluid(style='margin-top: 1px')
                          .p-col-6
                            span  <b>Formas de devolução *</b>
                          .p-col-6.ta-right
                            span <b>Total: {{formatPrice(totalEstornoCompleto)}} </b>
                      .p-grid.p-fluid
                          .p-col-12.p-md-3(v-if='novosEstornosList.length' v-for='elem, idx in novosEstornosList' :key='idx')
                              Card.payment-box
                                  template(slot='content')
                                      //- .ta-right(v-if='!estornoReadonly || (estorno.id != null && supervisao)')
                                      //-     a.btn-remove-payment(href='javascript:;' @click='novosEstornosList.splice(idx, 1);')
                                      //-         i.jam.jam-close
                                      .ta-center
                                          div.icon-box(v-if='elem.cd_forma_pagamento == 1')
                                              i.pi.pi-money-bill
                                          div.icon-box(v-if='elem.cd_forma_pagamento == 3')
                                              i.jam.jam-coin
                                          div.icon-box(v-if='elem.cd_forma_pagamento == 4')
                                              i.jam.jam-phone
                                          div.icon-box(v-if='elem.cd_forma_pagamento == 5')
                                              i.jam.jam-credit-card
                                          div.icon-box(v-if='elem.cd_forma_pagamento == 6')
                                              i.jam.jam-id-card
                                          p.text-method {{ elem.cd_forma_pagamento_f }}
                                          p.text-method {{ elem.nm_bandeira }}
                                          p.text-value {{ elem.nr_valor_f }}
                                          p.text-method(v-if="elem.nr_valor_juros" style='font-size: 13px; margin-top: 3px') + {{ elem.nr_valor_juros_f }} de juros
                                          p {{elem.nm_caixa}}
                                          p.highlight(v-if='supervisao' :class="{\
                                                em: elem.ie_situacao === 'P',\
                                                ex: elem.ie_situacao === 'R',\
                                            }") {{ options.ie_situacao_aux[elem.ie_situacao]?.toUpperCase() }}

                .p-col-12(v-if='estorno.id && estorno.cd_anexos.length > 0' style="align-self: flex-end;")
                  .p-inputgroup(v-for="(n_input, index) in estorno.cd_anexos" :key="index" v-if='n_input.aq_anexo')
                    .p-input-icon-left.p-input-icon-right
                        InputText(type="text" placeholder="Anexar comprovante" v-model="n_input.nm_anexo" readonly)
                        i.jam.jam-attachment(v-tooltip.top="'Anexar'")
                        i.jam.jam-close(v-show="n_input.aq_anexo" @click="removerComprovanteEstorno(n_input.id, index)"
                            v-tooltip.top="'Remover anexo'")
                    Button(:disabled="! n_input.aq_anexo" icon="pi pi-external-link"
                        v-tooltip.top="'Vizualizar comprovante'" @click="imprimirComprovanteEstorno(n_input.aq_anexo)")

                .p-col-12(v-if="estorno.id" style="align-self: flex-end;")
                    ProgressBar(v-if="waitingComprovanteEstorno" mode="indeterminate")
                    .p-inputgroup(v-else )
                        .p-input-icon-left.p-input-icon-right
                            InputText(type="text" placeholder="Anexar comprovante" v-model="comprovante.aq_anexo"
                                @click="$refs.comprovanteEstorno.click()" readonly)
                            i.jam.jam-attachment(@click="$refs.comprovanteEstorno.click()" v-tooltip.top="'Anexar'")


                        Button.p-button-success(
                          style="margin-right: flex-end;"
                          icon="pi pi-plus"
                          v-tooltip.top="'Adicionar comprovante'"
                          @click="$refs.comprovanteEstorno.click()")
                    input(
                        v-show='false'
                        type="file"
                        ref="comprovanteEstorno"
                        @change="anexarComprovanteEstorno()"
                        accept=".pdf, image/png, image/jpeg")
                .p-col-12
                    label.form-label Motivo *:
                    Dropdown.custom-dropdown(
                        :disabled='estornoReadonly'
                        v-model='estorno.cd_motivo_estorno'
                        optionValue='id'
                        :options='options.motivosEstorno'
                        optionLabel='ds_motivo'
                        dataKey='id'
                        )
                        template(#option="props")

                            .p-dropdown-car-option(v-if="props.option.ie_status")
                                span {{ props.option.ds_motivo }}
                            .p-dropdown-car-option.p-disabled(@click.stop="" v-else v-tooltip.left="'Motivo inabilitado'")
                                span {{ props.option.ds_motivo }}

                .p-col-12
                    label.form-label Motivo detalhado:
                    Textarea(:disabled='estornoReadonly' v-model='estorno.ds_motivo' rows='3' cols='30')

                .p-col-12
                    label.form-label Observações de pagamento:
                    Textarea(:disabled='estornoReadonly' v-model='estorno.ds_observacao_pagamento' rows='4' cols='30')

                .p-col-12.mt-2.mb-2(v-if="estorno.id")
                    .p-grid
                        .p-col-12.p-md-6
                            span <b>Usuário criação: </b> {{ estorno.nm_usuario_cri }}
                        .p-col-12.p-md-6
                            span <b>Data da criação: </b> {{ estorno.dt_criado_f }}
                        .p-col-12.p-md-6
                            span <b>Usuário edição: </b> {{ estorno.nm_usuario_edi }}
                        .p-col-12.p-md-6
                            span <b>Data da atualização: </b> {{ estorno.dt_atualizado_f }}
                        .p-col-12.p-md-6
                            span <b>Usuário aprovação: </b> {{ estorno.nm_usuario_apr }}
                        .p-col-12.p-md-6
                            span <b>Data da aprovação: </b> {{ estorno.dt_aprovado_f }}

                .p-col-4.ta-center(v-if='!estornoReadonly && !supervisao')
                .p-col-4.ta-center(v-if='!estornoReadonly && !supervisao')
                    ProgressSpinner.waiting-submit-estorno(v-if='waitingSubmitEstorno' strokeWidth="6")
                    Button(v-else label='Salvar' icon='jam jam-check' @click='estorno.ie_tipo = "D", openUsuario()' :disabled='!estorno.cd_motivo_estorno || waitingSubmitEstorno')
                .p-col-4.ta-center(v-if='!estornoReadonly && !supervisao')
                .p-col-6.ta-center(v-if='!estornoReadonly && supervisao')
                    ProgressSpinner.waiting-submit-estorno(v-if='waitingSubmitEstorno' strokeWidth="6")
                    Button(v-else label='Salvar' icon='jam jam-check' @click='salvarEstorno()' :disabled='!estorno.cd_motivo_estorno || waitingSubmitEstorno')
                .p-col-6.ta-center(v-if='!estornoReadonly && supervisao')
                    ProgressSpinner.waiting-submit-estorno(v-if='waitingSubmitEstorno' strokeWidth="6")
                    Button.p-button-success(v-else label='Salvar e aprovar' icon='jam jam-check' @click='salvarAprovar.show = true;' :disabled='!estorno.cd_motivo_estorno')




        Dialog.dialogAdicionarEstorno(:header="`${(estorno && estorno.id) ? 'Editar' : 'Adicionar'} estorno`"
            :modal='true' :visible.sync='dialogEstorno')
            .p-grid.p-fluid(style='height: 500px; overflow: scroll')
                .p-col-12
                  Panel(header="Guias" :toggleable="true")
                    DataTable.datatableGuias(:value='estorno.cd_guias')
                        Column(headerStyle='width:12%' field='cd_guia_eletronica' header='Cód.' bodyStyle='text-align:center')
                        Column(headerStyle='width:30%' field='nm_paciente' header='Paciente')
                            template(#body='props')
                                span {{ props.data.nm_paciente }}
                                span.d-block.o-hidden(style="font-size:10px") Usuário: {{ props.data.nm_usuario_atendimento }}
                        Column(headerStyle='width:30%' field='ds_procedimento' header='Procedimento')
                        Column(headerStyle='width:13%' field='ds_guia_situacao' header='Situação' bodyStyle='text-align:center')
                            template(#body='props')
                                p.highlight(:class="{\
                                    em: props.data.ds_guia_situacao === 'EMITIDA',\
                                    ex: props.data.ds_guia_situacao === 'EXECUTADA',\
                                    ca: props.data.ds_guia_situacao === 'CANCELADA'\
                                }") {{ props.data.ds_guia_situacao }}
                        Column(headerStyle='width:15%' field='nr_valortotal' header='Valor' bodyStyle='text-align:right')
                            template(#body='props')
                                span {{ formatPrice(props.data.valortotal) }}

                .p-col-6
                //- .p-col-6.ta-right
                //-     Button.p-button-success(v-if='estorno.ie_situacao == "PEN" && supervisao' label='Realizar todos os pagamentos pendentes' icon='jam jam-check' @click='openEstornoAprovarConfirm(estorno)')

                .p-col-12
                    label.form-label Tipo *:
                    SelectButton(v-model='estorno.ie_tipo' optionValue='value' :options='options.ie_tipo' optionLabel='label' dataKey='value'  :disabled='supervisao || estorno.id != null')
                .p-col-12(v-if='(estorno.ie_tipo == "D" && !this.supervisao) || (estorno.ie_tipo == "E" && this.supervisao) || (estorno.cd_forma_pagamento_estorno ? estorno.cd_forma_pagamento_estorno.length > 0 : estorno.cd_forma_pagamento_estorno)')
                    Panel
                      template(#header)
                        .p-grid.p-fluid(style='margin-top: 1px')
                          .p-col-6
                            span  <b>Formas de devolução *</b>
                          .p-col-6.ta-right
                            span <b>Total: {{formatPrice(totalEstorno)}} </b>
                      .p-grid.p-fluid
                          .p-col-12.p-md-3(v-if='novosEstornosList.length' v-for='elem, idx in novosEstornosList' :key='idx')
                              Card.payment-box
                                  template(slot='content')
                                      .ta-right(v-if='!estornoReadonly || (estorno.id != null && supervisao)')
                                          a.btn-remove-payment(href='javascript:;' @click='novosEstornosList.splice(idx, 1);')
                                              i.jam.jam-close
                                      .ta-center
                                          div.icon-box(v-if='elem.cd_forma_pagamento == 1')
                                              i.pi.pi-money-bill
                                          div.icon-box(v-if='elem.cd_forma_pagamento == 3')
                                              i.jam.jam-coin
                                          div.icon-box(v-if='elem.cd_forma_pagamento == 4')
                                              i.jam.jam-phone
                                          div.icon-box(v-if='elem.cd_forma_pagamento == 5')
                                              i.jam.jam-credit-card
                                          div.icon-box(v-if='elem.cd_forma_pagamento == 6')
                                              i.jam.jam-id-card
                                          p.text-method {{ elem.cd_forma_pagamento_f }}
                                          p.text-value {{ elem.nr_valor_f }}
                                          p.text-method(v-if="elem.nr_valor_juros" style='font-size: 13px; margin-top: 3px') + {{ elem.nr_valor_juros_f }} de juros
                                          p {{elem.nm_caixa}}
                                          p.highlight(v-if='supervisao' :class="{\
                                                em: elem.ie_situacao === 'P',\
                                                ex: elem.ie_situacao === 'R',\
                                            }") {{ options.ie_situacao_aux[elem.ie_situacao]?.toUpperCase() }}
                          .p-col-12.p-md-3(@click='mostrarNovoEstorno()' v-if='restaEstorno != 0')
                                Card.payment-box(style='cursor: pointer')
                                  template(slot='content')
                                      .ta-center
                                          div.icon-box
                                              i.jam.jam-plus
                                          p.text-method Adicionar forma de devolução

                .p-grid.p-fluid
                .p-col-3
                .p-col-3
                .p-col-3
                .p-col-3.ta-right(v-if='!restaEstorno == 0')
                    span.info-resta (Resta {{ formatPrice(restaEstorno) }})

                //- .p-col-12.p-md-6(v-if='estorno.id && usuarioNivel >= 5')
                //-     label.form-label Situação:
                //-     Dropdown(
                //-         :disabled='estornoReadonly'
                //-         v-model='estorno.ie_situacao'
                //-         :options='options.ieSituacao'
                //-         optionLabel='text'
                //-         optionValue='value'
                //-         dataKey='value')
                .p-col-12(v-if='estorno.id && estorno.cd_anexos.length > 0' style="align-self: flex-end;")
                  .p-inputgroup(v-for="(n_input, index) in estorno.cd_anexos" :key="index" v-if='n_input.aq_anexo')
                    .p-input-icon-left.p-input-icon-right
                        InputText(type="text" placeholder="Anexar comprovante" v-model="n_input.nm_anexo" readonly)
                        i.jam.jam-attachment(v-tooltip.top="'Anexar'")
                        i.jam.jam-close(v-show="n_input.aq_anexo" @click="removerComprovanteEstorno(n_input.id, index)"
                            v-tooltip.top="'Remover anexo'")
                    Button(:disabled="! n_input.aq_anexo" icon="pi pi-external-link"
                        v-tooltip.top="'Vizualizar comprovante'" @click="imprimirComprovanteEstorno(n_input.aq_anexo)")

                .p-col-12(v-if="estorno.id" style="align-self: flex-end;")
                    ProgressBar(v-if="waitingComprovanteEstorno" mode="indeterminate")
                    .p-inputgroup(v-else )
                        .p-input-icon-left.p-input-icon-right
                            InputText(type="text" placeholder="Anexar comprovante" v-model="comprovante.aq_anexo"
                                @click="$refs.comprovanteEstorno.click()" readonly)
                            i.jam.jam-attachment(@click="$refs.comprovanteEstorno.click()" v-tooltip.top="'Anexar'")


                        Button.p-button-success(
                          style="margin-right: flex-end;"
                          icon="pi pi-plus"
                          v-tooltip.top="'Adicionar comprovante'"
                          @click="$refs.comprovanteEstorno.click()")
                    input(
                        v-show='false'
                        type="file"
                        ref="comprovanteEstorno"
                        @change="anexarComprovanteEstorno()"
                        accept=".pdf, image/png, image/jpeg")


                //- .p-col-12( style="align-self: flex-end;")
                //-     ProgressBar(v-if="waitingComprovanteEstorno" mode="indeterminate")
                //-     .p-inputgroup(v-else v-for="(n_input, index) in estorno.cd_anexos" :key="index")
                //-         .p-input-icon-left.p-input-icon-right
                //-             InputText(type="text" placeholder="Anexar comprovante" v-model="n_input.nm_anexo"
                //-                 @click="$refs.comprovanteEstorno.click()" readonly)
                //-             i.jam.jam-attachment(@click="$refs.comprovanteEstorno.click()" v-tooltip.top="'Anexar'")
                //-             i.jam.jam-close(v-show="n_input.aq_anexo" @click="removerComprovanteEstorno(n_input.id)"
                //-                 v-tooltip.top="'Remover anexo'")
                //-         Button(:disabled="! n_input.aq_anexo" icon="pi pi-external-link"
                //-             v-tooltip.top="'Vizualizar comprovante'" @click="imprimirComprovanteEstorno(n_input.aq_anexo)")
                //-         Button.p-button-success(
                //-           style="margin-right: flex-end;"
                //-           v-if='estorno.cd_anexos.length == index + 1'
                //-           :disabled='!n_input.aq_anexo'
                //-           icon="pi pi-plus"
                //-           v-tooltip.top="'Adicionar comprovante'"
                //-           @click="addNovoComprovante(n_input)")
                //-     input(
                //-         v-show='false'
                //-         type="file"
                //-         ref="comprovanteEstorno"
                //-         @change="anexarComprovanteEstorno()"
                //-         accept=".pdf, image/png, image/jpeg")

                //- .p-col-12(style="align-self: flex-end;")
                //-     ProgressBar(v-if="waitingComprovante" mode="indeterminate")
                //-     .p-inputgroup(v-else)
                //-         .p-input-icon-left.p-input-icon-right
                //-             InputText(
                //-                 type="text"
                //-                 placeholder="Anexar comprovante"
                //-                 @click="$refs.refComprovante.click()"
                //-                 readonly)
                //-             i.jam.jam-attachment(@click="$refs.refComprovante.click()" v-tooltip.top="'Anexar'")
                //-             i.jam.jam-close(
                //-                 v-show="aq_deposito"
                //-                 @click="openConfirmRemoverComprovante()"
                //-                 v-tooltip.top="'Remover anexo'")
                //-         Button(
                //-             :disabled="!aq_deposito"
                //-             icon="pi pi-external-link"
                //-             v-tooltip.top="'Vizualizar comprovante'"
                //-             @click="abrirComprovante()")
                //-         Button(
                //-             icon="pi pi-plus"
                //-             v-tooltip.top="'Vizualizar comprovante'"
                //-             @click="abrirComprovante()")

                //-     input(
                //-         v-show='false'
                //-         type="file"
                //-         ref="refComprovante"
                //-         @change="anexarComprovante()"
                //-         accept=".")

                .p-col-12
                    label.form-label Motivo *:
                    Dropdown.custom-dropdown(
                        :disabled='estornoReadonly'
                        v-model='estorno.cd_motivo_estorno'
                        optionValue='id'
                        :options='options.motivosEstorno'
                        optionLabel='ds_motivo'
                        dataKey='id'
                        )
                        template(#option="props")

                            .p-dropdown-car-option(v-if="props.option.ie_status")
                                span {{ props.option.ds_motivo }}
                            .p-dropdown-car-option.p-disabled(@click.stop="" v-else v-tooltip.left="'Motivo inabilitado'")
                                span {{ props.option.ds_motivo }}

                .p-col-12
                    label.form-label Motivo detalhado:
                    Textarea(:disabled='estornoReadonly' v-model='estorno.ds_motivo' rows='3' cols='30')

                .p-col-12
                    label.form-label Observações de pagamento:
                    Textarea(:disabled='estornoReadonly' v-model='estorno.ds_observacao_pagamento' rows='4' cols='30')

                .p-col-12.mt-2.mb-2(v-if="estorno.id")
                    .p-grid
                        .p-col-12.p-md-6
                            span <b>Usuário criação: </b> {{ estorno.nm_usuario_cri }}
                        .p-col-12.p-md-6
                            span <b>Data da criação: </b> {{ estorno.dt_criado_f }}
                        .p-col-12.p-md-6
                            span <b>Usuário edição: </b> {{ estorno.nm_usuario_edi }}
                        .p-col-12.p-md-6
                            span <b>Data da atualização: </b> {{ estorno.dt_atualizado_f }}
                        .p-col-12.p-md-6
                            span <b>Usuário aprovação: </b> {{ estorno.nm_usuario_apr }}
                        .p-col-12.p-md-6
                            span <b>Data da aprovação: </b> {{ estorno.dt_aprovado_f }}

                .p-col-4.ta-center(v-if='!estornoReadonly && !supervisao')
                .p-col-4.ta-center(v-if='!estornoReadonly && !supervisao')
                    ProgressSpinner.waiting-submit-estorno(v-if='waitingSubmitEstorno' strokeWidth="6")
                    Button(v-else label='Salvar' icon='jam jam-check' @click='openUsuario()' :disabled='!estorno.cd_motivo_estorno || waitingSubmitEstorno')
                .p-col-4.ta-center(v-if='!estornoReadonly && !supervisao')
                .p-col-6.ta-center(v-if='!estornoReadonly && supervisao')
                    ProgressSpinner.waiting-submit-estorno(v-if='waitingSubmitEstorno' strokeWidth="6")
                    Button(v-else label='Salvar' icon='jam jam-check' @click='salvarEstorno()' :disabled='!estorno.cd_motivo_estorno || waitingSubmitEstorno')
                .p-col-6.ta-center(v-if='!estornoReadonly && supervisao')
                    ProgressSpinner.waiting-submit-estorno(v-if='waitingSubmitEstorno' strokeWidth="6")
                    Button.p-button-success(v-else label='Salvar e aprovar' icon='jam jam-check' @click='salvarAprovar.show = true;' :disabled='!estorno.cd_motivo_estorno')

        Dialog.dialogAdicionarEstorno(header="Corrigir Movimento"
            :modal='true' :visible.sync='dialogMovimento')

            .p-grid.p-fluid(style='height: 550px; overflow: scroll')
                .p-col-12
                    label.form-label.ml-1 {{ selecionados.length > 0 ? 'Movimentos' : 'Movimento'}}:
                    DataTable.datatableMovimentos(ref='datatableMovimentos' :value="selecionados" dataKey="id"
                                :rowClass="rowClass" class="p-datatable-striped"
                            )
                            Column(headerStyle='width: 10%;' bodyStyle='text-align: center' field='id' header='Cod')
                            Column(headerStyle='width: 35%;' header='Transação')
                                template(#body='props')
                                    b {{ props.data.nm_movimento_caixa }}
                                    span.d-block.o-hidden(style="font-size:10px") Usuário: {{ props.data.nm_usuario_cri }}
                                    em(v-if="props.data.ds_motivo != ''") {{ props.data.ds_motivo }}
                            Column(headerStyle='width: 10%;' bodyStyle='text-align: center' header='Data')
                                template(#body='props')
                                    span.ta-center.d-block {{ props.data.dt_movimento_f }}<br />
                            Column(headerStyle='width: 10%;' bodyStyle='text-align: center' field='ds_forma_pagamento' header='Forma')
                            Column(headerStyle='width: 15%;' bodyStyle='text-align: center' header='Valor')
                                template(#body='props')
                                    span(:class="{ 'valor-negativo': props.data.nr_valor < 0 }") {{ formatPrice(props.data.nr_valor) }}
                            .p-col-12
                    Panel.mt-2(header='Formas de pagamento' )
                        .p-grid.p-fluid
                            .p-col-12.p-md-3(v-if='novosPagamentosList.length' v-for='elem, idx in novosPagamentosList' :key='idx')
                                Card.payment-box
                                    template(slot='content')
                                        .ta-right(v-if='! $parent.imprimir')
                                            a.btn-remove-payment(href='javascript:;' @click='novosPagamentosList.splice(idx, 1);')
                                                i.jam.jam-close
                                        .ta-center(style='line-height: 20px')
                                            div.icon-box(v-if='elem.cd_forma_pagamento == 1')
                                                i.pi.pi-money-bill
                                            div.icon-box(v-if='elem.cd_forma_pagamento == 3')
                                                i.jam.jam-coin
                                            div.icon-box(v-if='elem.cd_forma_pagamento == 4')
                                                i.jam.jam-phone
                                            div.icon-box(v-if='elem.cd_forma_pagamento == 5')
                                                i.jam.jam-credit-card
                                            div.icon-box(v-if='elem.cd_forma_pagamento == 6')
                                                i.jam.jam-id-card
                                        .ta-center
                                            p.text-method {{ elem.cd_forma_pagamento_f }}<br/>
                                              span.text-parcelas(v-if='elem.ie_bandeira') {{ elem.ie_bandeira_f }}
                                            p.text-value {{ elem.nr_valor_f }}<br/>
                                            em(v-if='elem.nr_credito_parcelas' style='font-weight: normal; font-size: 14px; margin-top: 10px') {{elem.parcelas_f}}<br/>
                                            span(v-if='elem.nr_credito_parcelas' style='font-weight: normal; font-size: 14px') Total: {{ formatPrice(elem.valor_total) }}
                            .p-col-12.p-md-3(@click='mostrarNovoMovimento()' v-if="!resta == 0")
                                 Card.payment-box(style='cursor: pointer')
                                    template(slot='content')
                                        .ta-center
                                            div.icon-box
                                                i.jam.jam-plus
                                            p.text-method Adicionar Forma de Pagamento
                    .p-col-12
                        label.form-label Observações:
                        Textarea(v-model='ds_motivo_correcao' rows='3' cols='30')


            .p-grid.p-fluid
                .p-col-3
                .p-col-3
                .p-col-3
                    span.info-resta (Resta {{ formatPrice(resta) }})
                .p-col-3
                    Button.p-button-success(
                        :class='{"cursor-pointer": resta != 0}'
                        :disabled='resta != 0 || clickEnabled'
                        label='Finalizar'
                        icon="jam jam-plus"
                        @click='finalizarCorrecaoMovimento()'
                        )
                //- .p-col-4
                //-     Button(
                //-         label='Forma de pagamento'
                //-         icon="jam jam-plus"
                //-         @click='mostrarNovoMovimento()'
                //-         )

        Dialog.dialogCancelar(header='Cancelar guia com crédito' :modal='true' :visible.sync='dialogCancelar.display')
            .p-grid
                .p-col-12.ta-center(v-if='usuarioNivel >= 5')
                    p <b>Cancelar guia(s) {{ dialogCancelar.model.guias }} e gerar crédito</b> no valor de <b>{{ formatPrice(dialogCancelar.model.valorTotal) }}</b>
                    ProgressSpinner.waitingCancelarGuia(v-if='waitingCancelarGuia === dialogCancelar.model.guias' strokeWidth='6')
                    .p-grid.p-fluid.p-justify-center(v-else)
                      .p-col-6
                        Button.w-full.p-button-info(icon='jam jam-coin' label='Mudança de prestador'
                            @click='openDialogConfirmacao(true, true)')
                      .p-col-6
                        Button.w-full.p-button-success(icon='jam jam-coin' label='Gerar crédito'
                            @click='openDialogConfirmacao(true, false)')
                      


        Dialog.dialogAlterarPagante(header='Alterar pagante' :modal='true' :visible.sync='dialogAlterarPagante')
            BuscarPaciente(ref='buscarNovoPagante' label='Novo pagante' :somenteTitular='true' :cancelarPopup='true')
            .ta-center.my-2
                ProgressSpinner.waitingAlterarPagante(v-if='waitingAlterarPagante' strokeWidth='4')
                Button(v-else label='Confirmar alteração' icon='jam jam-check' @click='alterarPagante()')
        Dialog.dialogAlterarPagante(header='Adicionar Indicador' :modal='true' :visible.sync='dialogAtualizarIndicador')
            BuscarPaciente(ref='buscarIndicador' label='Novo Indicador' :somenteIndicador='true' :cancelarPopup='true')
            .ta-center.my-2
                ProgressSpinner.waiting-atualizar-indicador(v-if='waitingAtualizarIndicador' strokeWidth='4')
                Button(v-else label='Confirmar seleção' icon='jam jam-check' @click='atualizaIndicador()')


        ConfirmDialog(
            :display="dialogConfirmacao"
            @confirmed="cancelarGuias(dialogCancelar.model.guias, dialogCancelar.creditar, dialogCancelar.ie_troca_prestador)"
            @close="dialogConfirmacao = false")
            template(#text-content="props")
              .ta-center(v-if='dialogCancelar.ie_troca_prestador')
                span(v-if="dialogCancelar.creditar") Deseja mesmo <b>gerar crédito Medclub no valor
                    |  de {{ formatPrice(dialogCancelar.model.valorTotal) }} e trocar de prestador</b>?
                span(v-else) Deseja mesmo <b>cancelar sem efetuar estorno</b>?
              .ta-center(v-else)
                span(v-if="dialogCancelar.creditar") Deseja mesmo <b>cancelar e gerar crédito Medclub no valor
                    |  de {{ formatPrice(dialogCancelar.model.valorTotal) }} </b>?
                span(v-else) Deseja mesmo <b>cancelar sem efetuar estorno</b>?
              

        .ta-center(v-if='waiting')
            ProgressSpinner.spinner(strokeWidth="2")

        .p-grid.p-fluid.__box-imprimir.ta-left(v-else-if='model' style='margin: 0 auto; max-width: 800px;')
            .p-col-12.ta-center
                img.img-logo(src='./../../assets/img/logo-medclub.png')
            .p-col-12
                fieldset
                    legend.text-title Transação
                    .p-grid.p-fluid.box-no-print
                        .p-col-8
                            label.form-label Pagante:
                            .p-inputgroup
                                InputText(type='text' :readonly='true' v-model='model.nm_pagante')
                                Button(icon='jam jam-write' @click='$parent.$parent.$refs.buscarPaciente.dialogCadastro = true')
                                Button.p-button-warning(v-if='!model.ie_emitido_nf' icon='jam jam-refresh' @click='dialogAlterarPagante = true')
                        .p-col-2(v-if='model.nr_cpf_pagante_f')
                            label.form-label CPF:
                            InputText(type='text' :readonly='true' v-model='model.nr_cpf_pagante_f')
                        .p-col-2
                            label.form-label Créditos MedClub:
                            InputText.ta-right(type='text' readonly :value='formatPrice(valor_saldoPagante)' :style="{color: '#3c60ba', fontWeight: 'bold'}")
                        .p-col-4
                            label.form-label Caixa:
                            InputText(type='text' :readonly='true' v-model='model.nm_caixa')
                        .p-col-4
                            label.form-label Transação:
                            InputText(type='text' :readonly='true' v-model='model.nm_transacao_financeira')
                        .p-col-3.p-md-2
                            label.form-label Guia:
                            InputText(type='text' :readonly='true' v-model='model.id')
                        .p-col-3.p-md-2
                            label.form-label Senha:
                            InputText(type='text' :readonly='true' v-model="model.ds_hash")
                        .p-col-4
                            label.form-label Unidade:
                            InputText(type='text' :readonly='true' v-model='model.nm_unidade_medclub')
                        .p-col-4
                            label.form-label Setor:
                            InputText(type='text' :readonly='true' v-model='model.nm_setor')
                        .p-col-4
                            label.form-label Sala:
                            InputText(type='text' :readonly='true' v-model='model.nm_sala')
                        .p-col-4
                            label.form-label Data:
                            InputText(type='text' :readonly='true' v-model='model.dt_criado_f')
                        .p-col-4
                            label.form-label Valor total:
                            InputText(type='text' :readonly='true' :class="{ 'valor-negativo': model.nr_valor < 0 }" v-model="model.nr_valor_f")
                        .p-col-4
                            label.form-label Tarifa MedClub:
                            InputText(type='text' :readonly='true' :class="{ 'valor-negativo': model.vr_nota_guia < 0 }" v-model="model.vr_nota_guia_f")
                        .p-col-8(v-if="model.nm_indicador")
                            label.form-label Indicado por:
                            .p-inputgroup
                                InputText(type='text' :readonly='true' v-model='model.nm_indicador')
                                Button.p-button-warning(type='button' icon='jam jam-refresh-reverse' v-tooltip.top='"Alterar Indicação"' @click='dialogAtualizarIndicador=true')
                                Button.p-button-danger(type='button' icon='jam jam-trash' v-tooltip.top='"Remover Indicação"'
                                @click="atualizaIndicador(true)")
                        .p-col-12.ta-center(v-else)
                            Button.btn-incluir-movimento(type='button' icon='jam jam-plus' label='Adicionar Indicação'  v-tooltip.top='"Adicionar Indicação"' @click='dialogAtualizarIndicador=true')
                        .p-col-4
                        .p-col-8.ta-right
                            p(v-if='model.ie_emitido_nf')
                                i.jam(class='jam-check' style='font-size:20px;vertical-align:middle;font-weight:700')
                                strong.ml-1(style='vertical-align:middle') Nota fiscal emitida
                            p(v-else)
                                i.jam(class='jam-close' style='font-size:20px;vertical-align:middle;font-weight:700')
                                strong.ml-1(style='vertical-align:middle') Nota fiscal não emitida
                            p
                                i.jam(class='jam-user-circle' style='font-size:20px;vertical-align:middle;font-weight:700')
                                span.ml-1(style='vertical-align:middle') caixa: {{model.nm_usuario_cri}}
                            p
                                i.jam(class='jam-user-circle' style='font-size:20px;vertical-align:middle;font-weight:700')
                                span.ml-1(style='vertical-align:middle') atendimento: {{model.nm_usuario_atendimento}}

                    .box-print
                        .p-grid.p-grid.p-fluid
                          .p-col-6
                            p <b>Pagante: </b> {{ model.nm_pagante }}
                            p(v-if='model.nr_cpf_pagante_f') <b>CPF: </b> {{ model.nr_cpf_pagante_f }}
                            p <b>Caixa: </b> {{ model.nm_caixa }}
                            p <b>Sala: </b> {{ model.nm_sala }}
                            p <b>Setor: </b> {{ model.nm_setor }}
                            p <b>Unidade: </b> {{ model.nm_unidade }}
                          .p-col-6
                            p <b>Transação: </b> {{ model.nm_transacao_financeira }}
                            p <b>Data: </b> {{ model.dt_criado_f }}
                            p <b>Valor total: </b>
                              span(:class="{ 'valor-negativo': model.nr_valor < 0 }") {{ formatPrice(model.nr_valor) }}&nbsp;
                                  span.tarifa-text(v-if='model.vr_nota_guia') (Tarifa: {{ formatPrice(model.vr_nota_guia) }})
                        .p-grid
                            .p-col-6
                              //- p <b>Valor total: </b>
                              //-     span(:class="{ 'valor-negativo': model.nr_valor < 0 }") {{ formatPrice(model.nr_valor) }}&nbsp;
                              //-         span.tarifa-text(v-if='model.vr_nota_guia') (Tarifa: {{ formatPrice(model.vr_nota_guia) }})
                            .p-col-6.ta-right
                              p(v-if='model.ie_emitido_nf')
                                i.jam(class='jam-check' style='font-size:24px;vertical-align:middle;font-weight:700')
                                strong.ml-1 Nota fiscal emitida
                              p(v-else)
                                i.jam(class='jam-close' style='font-size:24px;vertical-align:middle;font-weight:700')
                                strong.ml-1 Nota fiscal não emitida
            // PARA IMPRESSAO
            .p-col-12.box-print(v-if='model.movimentos.length')
                fieldset
                    legend.text-title Movimentos
                    DataTable.datatableMovimentos(ref='datatableMovimentos' :value="model.movimentos" dataKey="id"
                            :rowClass="rowClass" class="p-datatable-striped"
                        )
                        Column(headerStyle='width: 8%;' header='Cod' field='id')
                        Column(headerStyle='width: 20%;' header='Transação')
                            template(#body='props')
                                b {{ props.data.nm_movimento_caixa }}
                                span.d-block.o-hidden(style="font-size:10px" v-if='props.data.cd_origem') Código de origem: <b> {{ props.data.cd_origem}}</b>
                                span.d-block.o-hidden(style="font-size:10px") Usuário: {{ props.data.nm_usuario_cri }}
                                em(v-if="props.data.ds_motivo != ''") {{ props.data.ds_motivo }}
                        Column(headerStyle='width: 15%;' header='Data')
                            template(#body='props')
                                span.ta-center.d-block {{ props.data.dt_movimento_f }}<br />
                        Column(headerStyle='width: 13%;' field='ds_forma_pagamento' header='Forma')
                              template(#body='props')
                                  span {{ props.data.ds_forma_pagamento }}
                                  span.d-block.o-hidden(v-if='props.data.ds_forma_pagamento == "Crédito"' style="font-size:10px") {{ props.data.nr_credito_parcelas }}x
                        Column(headerStyle='width: 12%;' header='Valor')
                            template(#body='props')
                                span(:class="{ 'valor-negativo': props.data.nr_valor < 0 }") {{ formatPrice(props.data.nr_valor) }}
                        Column(headerStyle='width: 10%;' bodyStyle='text-align: center' header='Juros')
                            template(#body='props')
                                span {{ formatPrice(props.data.nr_valor_juros) }}
                        Column(headerStyle='width: 10%;' bodyStyle='text-align: center' header='Valor Total')
                            template(#body='props')
                                span {{ formatPrice(props.data.nr_valor + props.data.nr_valor_juros) }}

            .p-col-12.box-print(v-if='model.estornos.length')
                fieldset
                    legend.text-title Estornos
                    DataTable.datatableEstornos(v-if='model.estornos.length' ref='datatableEstornos'
                        :value="model.estornos" dataKey="id" class="p-datatable-striped")
                        Column(headerStyle='width: 10%;' field='id' header='Cod.' bodyStyle='text-align:center;' )
                        Column(headerStyle='width: 40%;' header='Motivo')
                            template(#body='props')
                                span {{ options.motivosEstorno.find(e => e.id == props.data.cd_motivo_estorno).ds_motivo }}
                                span.d-block.o-hidden(style="font-size:10px") Usuário: {{ props.data.nm_usuario_cri }}
                        Column(headerStyle='width: 15%;' header='Situação' bodyStyle='text-align:center;')
                            template(#body='props')
                                p.highlight(v-if="props.data.ie_situacao === 'APR'" :class="{apr: true}") APROVADO
                                p.highlight(v-if="props.data.ie_situacao === 'CAN'" :class="{can: true}") CANCELADO
                                p.highlight(v-if="props.data.ie_situacao === 'PEN'" :class="{pen: true}") PENDENTE
                        Column(headerStyle='width: 15%;' field='dt_criado_f' header='Data' bodyStyle='text-align:center;')
                        Column(headerStyle='width: 15%;' field='dt_aprovado_f' header='Dt. Aprovação' bodyStyle='text-align:center;')
                        Column(headerStyle='width: 15%;' header='Valor' bodyStyle='text-align:right;')
                            template(#body='props')
                                span {{ formatPrice(props.data.nr_valor) }}

            .p-col-12.box-print(v-if='model.guias.length')
                fieldset
                    legend.text-title Guias
                    .p-col-12.ta-right(style='font-weight: bold')
                        p Valor Total: {{formatPrice(valorTotalGuias)}}
                    DataTable.datatableGuias(:value='model.guias' dataKey="id")
                        Column(headerStyle='width:8%' field='cd_guia_eletronica' header='Cód.')
                        Column(headerStyle='width:20%' field='nm_paciente' header='Paciente')
                            template(#body='props')
                                span {{ props.data.nm_paciente }}
                                span.d-block.o-hidden(style="font-size:10px") Usuário: {{ props.data.nm_usuario_atendimento }}
                        Column(headerStyle='width:20%' field='ds_procedimento' header='Procedimento')
                            template(#body='props')
                                span {{ props.data.ds_procedimento }}
                                span(v-if='props.data.nm_subgrupo')
                                    b(style='color: #3c60ba') | SUBGRUPO: &nbsp;
                                    b {{ props.data.nm_subgrupo }}
                        Column(headerStyle='width:14%' field='nm_estabelecimento' header='Estabelecimento')
                        Column(headerStyle='width:12%' field='ds_guia_situacao' header='Situação')
                            template(#body='props')
                                p.highlight(:class="{\
                                        em: props.data.ds_guia_situacao === 'EMITIDA',\
                                        ex: props.data.ds_guia_situacao === 'EXECUTADA',\
                                        ca: props.data.ds_guia_situacao === 'CANCELADA'\
                                    }") {{ props.data.ds_guia_situacao }}
                        Column(headerStyle='width:10%' field='nr_valortotal' header='Valor')
                            template(#body='props')
                                span(:class="{ 'valor-negativo': props.data.valortotal < 0 }") {{ formatPrice(props.data.valortotal) }}
            // PARA VISUALIZACAO
            .p-col-12.box-hide(v-if='model.movimentos.length')
                fieldset
                    legend.text-title Movimentos
                    DataTable.datatableMovimentos(ref='datatableMovimentos' :value="model.movimentos" dataKey="id"
                            :rowClass="rowClass" class="p-datatable-striped"
                        )
                        Column(headerStyle='width: 5%' bodyStyle='text-align:center' v-if='supervisao && !model.ie_guia_medclub_empresa')
                            template(#body='props')
                                Checkbox(v-if="!props.data.ie_corrigido && props.data.ie_tipo_movimento !== 'COR' && props.data.ie_permitir_correcao && supervisao" v-model='selecionados' :value='props.data' :id='props.id' )
                        Column(headerStyle='width: 10%;' header='Cod' field='id')
                        Column(headerStyle='width: 20%;' header='Transação')
                            template(#body='props')
                                b {{ props.data.nm_movimento_caixa }}
                                span.d-block.o-hidden(style="font-size:10px" v-if='props.data.cd_origem') Código de origem: <b> {{ props.data.cd_origem}}</b>
                                span.d-block.o-hidden(style="font-size:10px") Usuário: {{ props.data.nm_usuario_cri }}
                                em(v-if="props.data.ds_motivo != ''") {{ props.data.ds_motivo }}
                        Column(headerStyle='width: 10%;' header='Data')
                            template(#body='props')
                                span.ta-center.d-block {{ props.data.dt_movimento_f }}<br />
                        Column(headerStyle='width: 18%;' field='ds_forma_pagamento' header='Forma')
                              template(#body='props')
                                  span {{ props.data.ds_forma_pagamento }}
                                  span.d-block.o-hidden(v-if='props.data.ds_forma_pagamento == "Crédito"' style="font-size:10px") {{ props.data.nr_credito_parcelas }}x
                        Column(headerStyle='width: 12%;' header='Valor')
                            template(#body='props')
                                span(:class="{ 'valor-negativo': props.data.nr_valor < 0 }") {{ formatPrice(props.data.nr_valor) }}
                        Column(headerStyle='width: 10%;' bodyStyle='text-align: center' header='Juros')
                            template(#body='props')
                                span {{ formatPrice(props.data.nr_valor_juros) }}
                        Column(headerStyle='width: 10%;' bodyStyle='text-align: center' header='Valor Total')
                            template(#body='props')
                                span {{ formatPrice(props.data.nr_valor + props.data.nr_valor_juros) }}

                        //- Column(v-if='usuarioNivel >= 5' headerStyle='width: 10%;' bodyStyle='text-align: center;' header='Ações' headerClass='box-hide' bodyClass='box-hide')
                        //-     template(#body='props')
                        //-         Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(icon='jam jam-close-circle-f' @click='openRemover(props.data)')
                    .ta-center.box-hide(v-if='supervisao')
                        Button.btn-incluir-movimento.mr-2(type='button' :disabled='selecionados.length == 0' label='Corrigir Movimento' icon='jam jam-task-list'
                            @click="mostrarSelecionadosMovimento()")
                        //- Button.btn-incluir-movimento(type='button' label='Adicionar movimento' icon='jam jam-plus'
                        //-     @click="openAdicionarMovimento")


            .p-col-12.box-hide(v-if='model.estornos.length')
                fieldset
                    legend.text-title Estornos
                    DataTable.datatableEstornos(v-if='model.estornos.length' ref='datatableEstornos'
                        :value="model.estornos" dataKey="id" class="p-datatable-striped")
                        Column(headerStyle='width: 10%;' field='id' header='Cod.' bodyStyle='text-align:center;' )
                        Column(headerStyle='width: 40%;' header='Motivo')
                            template(#body='props')
                                span {{ options.motivosEstorno.find(e => e.id == props.data.cd_motivo_estorno).ds_motivo }}
                                span.d-block.o-hidden(style="font-size:10px") Usuário: {{ props.data.nm_usuario_cri }}
                        Column(headerStyle='width: 15%;' header='Situação' bodyStyle='text-align:center;')
                            template(#body='props')
                                p.highlight(v-if="props.data.ie_situacao === 'APR'" :class="{apr: true}") APROVADO
                                p.highlight(v-if="props.data.ie_situacao === 'CAN'" :class="{can: true}") CANCELADO
                                p.highlight(v-if="props.data.ie_situacao === 'PEN'" :class="{pen: true}") PENDENTE
                                p.highlight(v-if="props.data.ie_situacao === 'EDV'" :class="{edv: true}") EM DEVOLUÇÃO
                        Column(headerStyle='width: 15%;' field='dt_criado_f' header='Data' bodyStyle='text-align:center;')
                        Column(headerStyle='width: 15%;' field='dt_aprovado_f' header='Dt. Aprovação' bodyStyle='text-align:center;')
                        Column(headerStyle='width: 15%;' header='Valor' bodyStyle='text-align:right;')
                            template(#body='props')
                                span {{ formatPrice(props.data.nr_valor) }}
                        Column(headerStyle='width: 20%;' bodyStyle='text-align: center;' header='Ações' headerClass='box-hide' bodyClass='box-hide')
                            template(#body='props')
                                .ta-center(v-if='waitingImprimirEstornoId == props.data.id')
                                    ProgressSpinner(strokeWidth='6')
                                div(v-else)
                                    Button.p-button-raised.p-button-rounded.mr-1(v-if="props.data.ie_situacao === 'PEN' && usuarioNivel >= 5"
                                        v-tooltip.top="'Editar'" icon='jam jam-write' @click='openEstornoDialog(props.data, props.data.ie_situacao === "APR" ? true : false)')
                                    Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(v-if="(props.data.ie_situacao === 'PEN' && usuarioNivel >= 5) || props.data.cd_forma_pagamento_estorno.length == []"
                                        v-tooltip.top="'Cancelar'" icon='jam jam-close-circle-f' @click='openEstornoCancelarConfirm(props.data)')
                                    Button.p-button-raised.p-button-rounded.p-button-success.mr-1(v-if="(props.data.ie_situacao === 'PEN' || props.data.ie_situacao === 'EDV') && usuarioNivel >= 5 && supervisao"
                                        v-tooltip.top="'Aprovar'" icon='jam jam-check' style='margin-top: 4px' @click='openEstornoAprovarConfirm(props.data)')
                                    Button.p-button-raised.p-button-rounded.mr-1(v-if="props.data.ie_situacao !== 'PEN'"
                                        v-tooltip.top="'Vizualizar'" icon='pi pi-external-link' style='margin-top: 4px' @click='openEstornoDialog(props.data,  props.data.ie_situacao === "APR" ? true : false)')
                                    Button.p-button-raised.p-button-rounded.mr-1(v-if="props.data.ie_situacao !== 'CAN'"
                                        v-tooltip.top="'Imprimir'" icon='jam jam-printer' @click='openDialogImprimirEstorno(props.data.id)')
                                    Button.p-button-raised.p-button-rounded.mr-1.p-button-warning(v-if="props.data.ie_situacao === 'EDV'" v-tooltip.top="'Completar pagamentos pendentes'" icon='jam jam-credit-card' @click='openDialogEstornosTEFPendentes()')



                    .ta-right.mt-1(v-if='estornosCancelados.length')
                        Button.p-button-secondary.only-text(v-if='exibeEstornosCancelados' label='Esconder cancelados'
                            icon='jam jam-chevron-up' @click="esconderEstornosCancelados()")
                        Button.p-button-secondary.only-text(v-else label="Mostrar cancelados" icon='jam jam-chevron-down'
                            @click="mostrarEstornosCancelados()")

            .p-col-12.box-hide(v-if='model.guias.length')
                fieldset
                    legend.text-title Guias
                    .p-col-12.ta-right(style='font-weight: bold')
                        p Valor Total: {{formatPrice(valorTotalGuias)}}
                    DataTable.datatableGuias(:value='model.guias' dataKey="id")
                        Column(v-if='!model.ie_guia_medclub_empresa' headerStyle='width: 5%;' bodyStyle='text-align:center' )
                          template(#header)
                            Checkbox(v-model='selecionarTodos' binary)
                          template(#body='props')
                            Checkbox(v-model='guiasEstorno' :value='props.data' :id='props.id'
                              v-if="['EMITIDA','EXECUTADA','PROCESSANDO', 'AGUARDANDO RESULTADO'].includes(props.data.ds_guia_situacao)")
                        Column(headerStyle='width:8%' field='cd_guia_eletronica' header='Cód.')
                        Column(headerStyle='width:20%' field='nm_paciente' header='Paciente')
                            template(#body='props')
                                span {{ props.data.nm_paciente }}
                                span.d-block.o-hidden(style="font-size:10px") Usuário: {{ props.data.nm_usuario_atendimento }}
                        Column(headerStyle='width:18%' field='ds_procedimento' header='Procedimento')
                            template(#body='props')
                                span {{ props.data.ds_procedimento }}
                                span(v-if='props.data.nm_subgrupo')
                                    b(style='color: #3c60ba') | SUBGRUPO: &nbsp;
                                    b {{ props.data.nm_subgrupo }}
                        Column(headerStyle='width:16%' field='nm_estabelecimento' header='Estabelecimento')
                        Column(headerStyle='width:12%' field='ds_guia_situacao' header='Situação')
                            template(#body='props')
                                p.highlight(:class="{\
                                        em: props.data.ds_guia_situacao === 'EMITIDA',\
                                        ex: props.data.ds_guia_situacao === 'EXECUTADA',\
                                        ca: props.data.ds_guia_situacao === 'CANCELADA'\
                                    }") {{ props.data.ds_guia_situacao }}
                        Column(headerStyle='width:10%' field='nr_valortotal' header='Valor')
                            template(#body='props')
                                span(:class="{ 'valor-negativo': props.data.valortotal < 0 }") {{ formatPrice(props.data.valortotal) }}
                        Column(headerStyle='width:12%' header='Ações' headerClass='box-hide' bodyClass='box-hide')
                            template(#body='props')
                                .ta-center
                                    //- div(v-if="props.data.ds_guia_situacao !== 'CANCELADA'" style='display:inline-block;vertical-align:middle')
                                    //-     Button.p-button-raised.p-button-rounded.p-button-danger(v-tooltip.top="'Cancelar guia'" icon='jam jam-minus-circle' @click='openDialogCancelar(props.data)')
                                    Button.p-button-raised.p-button-rounded.p-button-warning.mr-1(v-tooltip.top="'Desfazer cancelamento'"
                                        v-if='props.data.ie_medcred && supervisao' icon='jam jam-redo' :disabled='model.ie_guia_medclub_empresa'
                                        @click='openDesfazerCancelamento(props.data.cd_guia_eletronica)')
                                    Button.p-button-raised.p-button-rounded(v-tooltip.top="'Imprimir'"
                                        v-if='props.data.cd_horario_agendamento_id' icon='jam jam-printer'
                                        @click='dialogOpcoesImpressao.cd_horario_agendamento=props.data.cd_horario_agendamento_id; dialogOpcoesImpressao.visible=true')

            .p-col-12.ta-right
                p.text-footer Data da impressão: {{ dataImpressao }}
</template>

<style lang="scss">
.imprimir-transacao {
  .info-resta {
    font-size: 15px;
    font-weight: 700;
    color: #888;
  }
  .custom-svg-icon-2 {
			background: url("../../assets/img/estorno.svg");
      top: 9.5px;
      left: 10px;
			height: 23px;
			width: 23px;
			color: white
		}
  p.highlight {
    background-color: #c9daf8;
    display: inline;
    font-weight: 700;

    &.em {
      background-color: #fff2cc;
    }
    &.ex {
      background-color: #c5efcb;
    }
    &.ca {
      background-color: #f8d6d9;
    }

    &.apr {
      background-color: #c5efcb;
    }
    &.can {
      background-color: #f8d6d9;
    }
    &.pen {
      background-color: #fff2cc;
    }
    &.edv {
      background-color: #FE9635;
    }
  }
  .custom-svg-icon {
			background: url("../../assets/img/estorno.svg");
			top: 11px;
			height: 23px;
			width: 23px;
			color: white
		}
  .box-no-print {
    .form-label,
    input {
      font-size: 12px;
    }
    > div {
      padding: 2px 6px !important;
    }
  }
  .input-adicionar {
    text-align: right;
    font-weight: 700;
  }
  .checkbox-nf {
    .p-checkbox-box {
      width: 16px !important;
      height: 16px !important;
      .p-checkbox-icon {
        font-size: 14px !important;
      }
    }
  }
  .waitingApagar,
  .waitingAdicionar,
  .waitingCancelarGuia,
  .waitingImprimir,
  .waiting-submit-estorno {
    width: 27px;
    height: auto;
  }
  .waitingAlterarPagante,
  .waiting-atualizar-indicador {
    width: 25px;
    height: auto;
  }
  .waitingNF {
    width: 13px !important;
    height: 13px !important;
    margin-right: 4px !important;
  }
  .btn-incluir-movimento {
    max-width: 200px;
    font-size: 12px;
    margin-top: 10px;
  }
  .dialog-opcoes-impressao {
    width: 600px;
    max-width: 96%;
  }
  .dialogRemover,
  .dialogAdicionar,
  .dialogCancelar {
    width: 96%;
    max-width: 500px;
    .p-dialog-content {
      overflow: visible !important;
    }
  }
  .dialogAdicionarEstorno {
    width: 98%;
    max-width: 750px;
    .p-dialog-content {
      overflow: visible !important;
    }
  }
  .dialogCancelamentosPendentes {
    width: 98%;
    max-width: 900px;
    .p-dialog-content {
      overflow: visible !important;
    }
  }
  .dialogUsuario {
    .p-dialog-content {
      overflow-y: visible !important;
    }
  }
  .dialogAlterarPagante {
    width: 96%;
    max-width: 720px;
    .buscar-paciente {
      .form-header {
        display: block;
      }
      .p-dialog-content {
        overflow: visible !important;
      }
    }
    .dialogCadastro {
      margin-top: -50%;
      width: auto;
    }
  }
  .p-inputtext.readonly {
    font-weight: 700;
    background-color: #ddd;
    color: #666;
  }
  .spinner {
    margin: 100px 0;
  }
  .text-footer {
    font-size: 12px;
    margin-top: -10px !important;
  }
  .img-logo {
    max-width: 160px;
    @media screen {
      display: none;
    }
  }
  .tarifa-text {
    font-weight: 700;
    color: #657786;
  }
  .payment-box {
    position: relative;
    .p-button-success {
      .cursor-pointer {
        cursor: not-allowed !important;
      }
    }
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    .text-method {
      font-size: 18px;
      margin: 0;
      margin-top: 14px;
    }
    .text-value {
      font-size: 22px;
      font-weight: 700;
      margin: 0;
      margin-top: 10px;
    }
    .text-parcelas {
      font-size: 15px;
      margin-top: 14px;
    }
    .icon-box {
      width: 50px;
      height: 50px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #312c66;
      i {
        font-size: 20px;
        color: #fff;
      }
    }
    .btn-remove-payment {
      display: inline-block;
      position: absolute;
      top: 2px;
      right: 2px;
      background-color: #c8c8c8;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 16px;
      border-radius: 50%;
      i {
        font-size: 20px;
        color: #666;
      }
    }
  }
  .__box-imprimir {
    border: 1px solid #c8c8c8;
    padding: 20px;
    @media screen {
      max-width: 900px !important;
    }
    fieldset {
      border: 1px solid #c8c8c8;
    }
    p {
      margin: 2px 0;
      font-size: 12px;
      color: #333;
    }
    .text-title {
      color: #657786;
      font-size: 14px;
      font-weight: 700;
    }
    .wrapper1 {
      .wrapper-inner {
        border: 1px solid #c8c8c8;
        padding: 6px 10px;
      }
      padding: 2px;
      width: 50%;
      display: inline-block;
      vertical-align: top;
    }
    .table {
      border-bottom: 1px solid #c8c8c8;
      .row {
        font-size: 0;
        border-top: 1px solid #c8c8c8;
        border-left: 1px solid #c8c8c8;
        .col {
          display: inline-block;
          vertical-align: middle;
          font-size: 12px;
          border-right: 1px solid #c8c8c8;
          padding: 2px 6px;
        }
      }
    }
    .p-progress-spinner {
      height: 29px;
      width: 29px;
    }
    @media print {
      .box-hide {
        display: none;
      }
      .box-no-print {
        display: none;
      }
    }
    @media screen {
      .box-print {
        display: none;
      }
    }
    .datatableMovimentos,
    .datatableGuias,
    .datatableEstornos {
      .p-column-title,
      td {
        font-size: 12px;
      }
    }
  }
  .parcelas {
    .p-dropdown-item {
      display: inline-grid;
      width: 100%;
      word-wrap: break-word;
      white-space: initial;
      .p-highlight {
        color: white !important;
      }
    }
  }
  .border {
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 5px;
    padding: 10px;
    min-height: 150px;
  }
  .p-button.p-button-secondary.only-text,
  .p-button.p-button-secondary.only-text:enabled,
  .p-button.p-button-secondary.only-text:hover,
  .p-button.p-button-secondary.only-text:focus {
    background: none;
    border: none;
    box-shadow: none;
    font-size: 12px;
    font-weight: 600;
    max-width: 170px;
  }
  .p-input-icon-left,
  .p-input-icon-right {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .p-input-icon-left > i,
  .p-input-icon-right > i {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
    cursor: pointer;
  }
  .p-input-icon-left > i:first-of-type {
    left: 0.5rem;
    color: #6c757d;
  }
  .p-input-icon-right > i:last-of-type {
    right: 0.5rem;
    color: #6c757d;
  }
  .p-input-icon-left > .p-inputtext {
    padding-left: 2rem;
  }
  .p-input-icon-right > .p-inputtext {
    padding-right: 2rem;
  }
  .p-datepicker {
    top: 0px !important;
    left: 0px !important;
  }
  .custom-dropdown {
    .p-dropdown-item {
      padding: 0 !important;
    }
    .p-dropdown-car-option {
      padding: 0.429em 0.857em;
    }
  }
}
</style>

<script>
import BuscarPaciente from "./../Paciente/BuscarPaciente";
import SelectButton from "primevue/selectbutton";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import Column from "primevue/column";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import InputText from "primevue/inputtext";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import Textarea from "primevue/textarea";
import Tooltip from "primevue/tooltip";
import Panel from "primevue/panel";
import moment from "moment";
import Message from "primevue/message";
import CurrencyInput from "@/components/CustomComponents/CurrencyInput";
import CustomIconEstorno from "@/components/CustomComponents/icons/CustomIconEstorno"
import { pCalendarLocale_ptbr } from "./../../utils";
import { Caixas, Paciente, DominioValor, TransacaoCartao } from "../../middleware";
import ConfirmDialog from "@/components/CustomComponents/ConfirmDialog";
const _ = require("lodash");

export default {
  components: {
    ConfirmDialog,
    BuscarPaciente,
    Button,
    Calendar,
    Checkbox,
    Column,
    DataTable,
    Dialog,
    Dropdown,
    FileUpload,
    InputText,
    ProgressBar,
    ProgressSpinner,
    Textarea,
    Tooltip,
    Panel,
    Card,
    CurrencyInput,
    SelectButton,
    Message,
    CustomIconEstorno
  },
  directives: { tooltip: Tooltip },
  created() {
    let usuario = JSON.parse(localStorage.getItem("usuario"));
    this.usuarioNivel = usuario.nivel;
    this.waiting = true;
    this.verificarRota()
    if (this.id) {
      this.getTransacao();

    } else this.waiting = false;

    Caixas.getFormasPagamento().then((response) => {
      if (response.status == 200) {
        response.data.forEach((elem) => {
          this.options.formasPagamentoMovimento.push({
            value: elem.id,
            text: elem.nm_forma_pagamento,
          });
        });
        this.model.cd_forma_pagamento = "";
      }
    });
    Caixas.getjuros().then((response) => {
      if (response.status == 200) {
        this.options.parcelas = _.cloneDeep(response.data);
        this.options.parcelasCredishop = _.cloneDeep(response.data);
      }
    });
    this.getDominios();
    this.getMotivosEstorno();
  },
  computed: {
    selecionarTodos: {
      get() {
        // for (let val of this.model.guias) {
        //   console.log("VAUM 1", val)
        //   if((!this.guiasEstorno.find(x => x.cd_guia_eletronica === val.cd_guia_eletronica))){
        //     console.log("ENTROUM")
        //     console.log("VAUM 2", val)
        //     return false
        //   }

        // }
        return (
          this.guiasEstorno.length &&
          this.model.guias.every(
            (g) =>
              g.ds_guia_situacao == "CANCELADA" ||
              this.guiasEstorno.find(
                (x) => x.cd_guia_eletronica === g.cd_guia_eletronica
              )
          )
        );
        // return this.guiasEstorno.length && this.guiasEstorno.find(x => x.cd_guia_eletronica === val.cd_guia_eletronica)

        // console.log("SAIUM")
        // return true
      },
      set(val) {
        if (val) {
          this.guiasEstorno = this.model.guias.filter(
            (item) => item.ds_guia_situacao != "CANCELADA"
          );
          // let filtrado = this.model.guias.filter(item => {
          // 	return this.guiasEstorno.findIndex(x => x.cd_guia_eletronica === item.cd_guia_eletronica && item.) === -1
          // })
          // this.guiasEstorno = [...this.guiasEstorno, ...filtrado]
        } else {
          this.guiasEstorno = [];
          // this.guiasEstorno = this.guiasEstorno.filter(item => {
          // 	return this.model.guias.findIndex(x => x.cd_guia_eletronica === item.cd_guia_eletronica) === -1
          // })
        }
      },
    },
    resta() {
      var totalSelecionados =
        this.selecionados.length > 0
          ? this.selecionados.reduce(
              (accumalator, currentValue) =>
                accumalator + currentValue.nr_valor,
              0
            )
          : 0;
      var totalNovosPagamentos =
        this.novosPagamentosList.length > 0
          ? this.novosPagamentosList.reduce(
              (accumalator, currentValue) =>
                accumalator + currentValue.nr_valor,
              0
            )
          : 0;
      return parseFloat(totalSelecionados - totalNovosPagamentos);
    },
    restaEstorno() {
      var totalSelecionados = this.estorno.cd_guias
        ? this.estorno.cd_guias.reduce(
            (accumalator, currentValue) =>
              accumalator + currentValue.valortotal,
            0
          )
        : 0;
      var totalNovosPagamentos =
        this.novosEstornosList.length > 0
          ? this.novosEstornosList.reduce(
              (accumalator, currentValue) =>
                accumalator + currentValue.nr_valor,
              0
            )
          : 0;

      return parseFloat(totalSelecionados - totalNovosPagamentos);
    },
    totalEstorno() {
      var totalSelecionados = this.estorno.cd_guias
        ? this.estorno.cd_guias.reduce(
            (accumalator, currentValue) =>
              accumalator + currentValue.valortotal,
            0
          )
        : 0;
      return parseFloat(totalSelecionados);
    },
    totalEstornoCompleto() {
      var totalSelecionados = this.novosEstornosList
        ? this.novosEstornosList.reduce(
            (accumalator, currentValue) =>
              accumalator + (currentValue.nr_valor),
            0
          )
        : 0;
      return parseFloat(totalSelecionados);
    },
    total_cirurgia() {
      var valor = this.movimento.nr_valor ? this.movimento.nr_valor : 0;
      var juros = this.movimento.nr_valor_juros
        ? this.movimento.nr_valor_juros
        : 0;
      return parseFloat(valor + juros);
    },
    juros() {
      return this.movimento?.juros ?? 0;
    },
  },
  watch: {
    guiasEstorno: function (val) {
      this.$emit("setGuias", val.length);
    },
    model: function (val) {
      console.log(val)
      this.$emit("setMedclubEmpresa", val.ie_guia_medclub_empresa);
    },
    "movimento.nr_valor": function (valor) {
      if (valor == 0) this.movimento.nr_credito_parcelas = null;
      else if (this.options.operadoras != null) {
        this.ie_cobrar_cliente = this.options.operadoras.find(
          (item) => item.id === this.movimento.cd_operadora
        )?.ie_cobrar_cliente;
        console.log(this.ie_cobrar_cliente);
        if (
          !this.options.operadoras.find(
            (item) => item.id === this.movimento.cd_operadora
          )?.ie_cobrar_clientes
        ) {
          this.options.parcelasCredishop.forEach((item) => {
            item.text = `${item.nr_parcela} X ${this.formatPrice(
              (valor * 1) / +item.nr_parcela
            )}`;
            item.value = +item.nr_parcela;
            item.valor_total = this.movimento.nr_valor * 1;
          });
          this.options.parcelasCredishop =
            this.options.parcelasCredishop.filter((i) => i.value <= 6);
        } else {
          this.options.parcelas.forEach((item) => {
            item.text = `${item.nr_parcela} X ${this.formatPrice(
              (valor * +item.nr_juros) / +item.nr_parcela
            )}`;
            item.value = +item.nr_parcela;
            item.valor_total = valor * +item.nr_juros;
          });
        }
        if (this.movimento.nr_credito_parcelas != null)
          this.$toast.info(
            "As taxas mudaram, selecione as parcelas novamente",
            { duration: 6000 }
          );
        this.movimento.nr_credito_parcelas = null;
      }
    },
    "movimento.cd_operadora": function (valor) {
      this.movimento.ie_bandeira = null;
      this.ie_cobrar_cliente = this.options.operadoras.find(
        (item) => item.id === valor
      )?.ie_cobrar_cliente;
      if (
        !this.options.operadoras.find((item) => item.id === valor)
          ?.ie_cobrar_cliente
      ) {
        this.options.parcelasCredishop.forEach((item) => {
          item.text = `${item.nr_parcela} X ${this.formatPrice(
            (this.movimento.nr_valor * 1) / +item.nr_parcela
          )}`;
          item.value = +item.nr_parcela;
          item.valor_total = this.movimento.nr_valor * 1;
        });
        this.options.parcelasCredishop = this.options.parcelasCredishop.filter(
          (i) => i.value <= 6
        );
      } else {
        this.options.parcelas.forEach((item) => {
          item.text = `${item.nr_parcela} X ${this.formatPrice(
            (this.movimento.nr_valor * +item.nr_juros) / +item.nr_parcela
          )}`;
          item.value = +item.nr_parcela;
          item.valor_total = this.movimento.nr_valor * +item.nr_juros;
        });
      }
      if (this.movimento.nr_credito_parcelas != null)
        this.$toast.info("As taxas mudaram, selecione as parcelas novamente", {
          duration: 6000,
        });
      this.movimento.nr_credito_parcelas = null;
    },
    "movimento.cd_forma_pagamento": function (value) {
      if (value) {
        Caixas.getBandeirasOperadoras({ cd_forma_pagamento: value }).then(
          (response) => {
            this.options.operadoras = response.data;
            response.data.forEach((item) => {
              this.options.bandeiras[item.id] = item.cd_bandeiras;
            });
          }
        );
      }
    },
    dialogMovimento: function (value) {
      if (!value) this.novosPagamentosList = [];
    },
    // "selecionarTodos": function(value) {
    //   if(value) {
    //     this.guiasEstorno = this.model.guias.filter(item => {
    //       if(item.ds_guia_situacao != "CANCELADA") return item
    //     })
    //   } else {
    //      this.guiasEstorno = []
    //   }
    // },
    dialogAdicionar: function () {
      setTimeout(() => {
        let el = document.querySelector(".input-adicionar");
        if (el) {
          let _setCursorEnd = (ev) => {
            if (ev.type == "keydown" && ev.keyCode == 46) {
              this.model.nr_valor_adicionar = 0;
            }
            let pos = el.value.length;
            el.setSelectionRange(pos, pos);
          };
          el.onclick = _setCursorEnd;
          el.onkeydown = _setCursorEnd;
        }
      });
    },
    searchQuery: _.debounce(function () {
      this.isTyping = false;
    }, 500),
    isTyping: function (value) {
      if (!value) {
        this.movimento.nr_valor = this.searchQuery;
        if (this.movimento.cd_forma_pagamento === "5") this.findJuros();
        this.movimento.nr_valor = this.searchQuery;
      }
    },
  },
  props: ["id", "guias", "estornoTotalPossivel"],
  data() {
    return {
      model: {},
      usuario: {
        user: "",
        senha: "",
      },
      valorTotalGuias: 0,
      ptbr: pCalendarLocale_ptbr,
      aq_deposito: null,
      isTyping: false,
      searchQuery: 0,
      selecionados: [],
      waitingSelecionados: [],
      waitingSelecionarVarios: false,
      mostrarSelecionados: false,
      waiting: false,
      exibeSaldo: false,
      valor_saldoPagante: 0,
      ds_motivo_correcao: "",
      salvarAprovar: {
        show: false,
        id: null,
      },
      dataImpressao: moment().format("DD/MM/YYYY - HH:mm:ss"),
      usuarioNivel: 0,
      waitingApagar: false,
      waitingAdicionar: false,
      waitingCorrecao: false,
      waitingCancelarGuia: 0,
      waitingAlterarPagante: false,
      waitingAtualizarIndicador: false,
      waitingAprovarEstorno: false,
      waitingComprovante: false,
      waintingUsuario: false,
      waitingCancelamento: false,
      waitingNF: false,
      dialogRemover: false,
      dialogAdicionar: false,
      dialogAlterarPagante: false,
      dialogAtualizarIndicador: false,
      dialogUsuario: false,
      dialogImprimirEstorno: {
        visible: false,
        waiting: false,
        id: null,
      },
      movimento: {
        cd_pagante: null,
        cd_atendimento: null,
        cd_forma_pagamento: null,
        nr_valor: 0,
        nr_credito_parcelas: null,
        ie_registro_manual: true,
        cd_operadora: null,
        ie_bandeira: null,
        nr_final_cartao: null,
        nr_inicio_cartao: null,
        cd_autorizacao: null,
        nr_pix_doc: null,
        ie_situacao: null,
        nr_valor_juros: 0,
        juros: null,
      },
      ie_cobrar_cliente: false,
      ie_estorno_total: false,
      estornosTEFPendentes: [],
      dialogEstornosPendentes: false,
      waitingEstornosPendentes: false,
      dialogEstorno: false,
      dialogEstornoTotal: false,
      dialogMovimento: false,
      novosPagamentosList: [],
      novosEstornosList: [],
      dialogNovoPagamento: false,
      waitingSubmitEstorno: false,
      estorno: {},
      // selecionarTodos: false,
      formasPagamentos: [],
      guiasEstorno: [],
      estornoReadonly: false,
      estornoCancelarConfirm: false,
      waitingCancelarEstorno: false,
      waitingComprovanteEstorno: false,
      waitingImprimirEstornoId: 0,
      estornosCancelados: [],
      exibeEstornosCancelados: false,
      countComprovantesEstornos: 0,
      comprovante: {},
      clickEnabled: false,
      dialogNovoEstorno: false,
      dialogAprovarEstorno: false,
      dialogConfirmacao: false,
      dialogDesfazerCancelamento: {
        show: false,
        id: null,
      },
      supervisao: false,
      dialogCancelar: {
        model: {},
        creditar: false,
        ie_troca_prestador: false,
        display: false,
      },
      dialogOpcoesImpressao: {
        visible: false,
        cd_horario_agendamento: null,
        waiting: false,
      },
      anexoEstorno: {
        formData: null,
        nm_anexo: "",
      },
      options: {
        parcelas: [],
        parcelasCredishop: [],
        formasPagamento: [],
        formasPagamentoMovimento: [],
        ie_tipo: [],
        bandeiras: [],
        operadoras: [],
        operacao: [
          { value: "C", text: "Crédito" },
          { value: "D", text: "Débito" },
        ],
        ie_situacao: [],
        ie_situacao_aux: {},
        ieSituacao: [
          { value: "PEN", text: "Pendente" },
          { value: "APR", text: "Aprovado" },
          { value: "CAN", text: "Cancelado" },
        ],
        motivosEstorno: [],
      },
    };
  },
  methods: {
    openDesfazerCancelamento(id){
      this.dialogDesfazerCancelamento.show = true
      this.dialogDesfazerCancelamento.id = id
    },
    desfazCancelamento(){
      Caixas.desfazerCancelamento({ id: this.dialogDesfazerCancelamento.id }).then(response => {
          if(response.status === 200) {
              this.$toast.success("Operação realizada com sucesso.");
              location.reload()
          } else if ([400, 401].includes(response.status)) {
              if (response.data.detail) {
                  if (typeof response.data.detail == 'string') {
                      this.$toast.error(response.data.detail, { duration: 3000 });
                  } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
              } else if (response.data){
                  Object.values(response.data).forEach(error => {
                      error.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                  })
              }
          }
          this.dialogDesfazerCancelamento.id = null
      })
    },
    formatPrice(val) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(val);
    },
    rowClass(a) {
      return `_row_${a.id}`;
    },
    guiasCancel() {
      return this.guiasEstorno.length > 0 ? true : false;
    },
    verificarRota() {
      let rotaAtual = this.$route.path.split("/")[1];
      this.supervisao = rotaAtual === "transacoes-financeiras";
    },
    openRemover(movimento) {
      this.movimento = Object.assign({}, movimento);
      this.movimento.nr_valor_f = this.formatPrice(this.movimento.nr_valor);
      this.movimento.ds_motivo = "";
      this.movimento.dt_movimento = moment(movimento.dt_criado)._d;
      this.dialogRemover = true;
    },
    openAdicionarMovimento() {
      this.movimento = {
        cd_forma_pagamento: this.options.formasPagamento[0].value,
        nr_valor: 0,
        ds_motivo: "",
        ie_operacao: "C",
        dt_movimento: moment()._d,
      };
      this.saldoPaciente();
      this.exibeSaldo = false;
      this.dialogAdicionar = true;
    },
    openUsuario() {
      this.waitingSubmitEstorno = true
      if (!this.estorno.ie_tipo) {
        this.$toast.error("Informe o tipo de solitação.");
        return;
      } else if (this.estorno.ie_tipo == "E") {
        this.salvarEstorno();
      } else {
        if (!this.estorno.cd_motivo_estorno) {
          this.$toast.error("Informe o motivo do estorno.");
          return;
        }
        this.dialogUsuario = true;
      }
      this.waitingSubmitEstorno = false
    },
    confirmarUsuario() {
      this.waintingUsuario = true;
      Caixas.solicitacaoEstornoOperacional(this.usuario).then((response) => {
        if (response.status === 200) {
          this.estorno.cd_coordenador = response.data.cd_coordenador;
          this.dialogUsuario = false;
          this.openDialogEstornosTEFPendentes();
          this.salvarEstorno();
        } else {
          if (response.data.detail) {
            if (typeof response.data.detail == "string")
              this.$toast.error(response.data.detail, { duration: 3000 });
            else
              response.data.detail.forEach((msg) =>
                this.$toast.error(msg, { duration: 3000 })
              );
          }
        }
        this.waintingUsuario = false;
        
      });
    },
    getDominios() {
      DominioValor.findAll({
        ds_mnemonico: [
          "SITUACAO_PAGAMENTO_ESTORNO",
          "FATOR_CIELO_PARCELAS",
          "FORMA_PAGAMENTO_ESTORNO",
          "TIPO_SOLICITACAO",
        ],
      }).then((response) => {
        response.data.SITUACAO_PAGAMENTO_ESTORNO?.valores.forEach((item) => {
          // if(item.ie_valor = 'C') {
          this.options.ie_situacao.push({
            label: item.ds_valor,
            value: item.ie_valor,
          });
          this.options.ie_situacao_aux[item.ie_valor] = item.ds_valor;

          // }
        });
        this.options.juros = response.data.FATOR_CIELO_PARCELAS?.valores;
        response.data.FORMA_PAGAMENTO_ESTORNO?.valores.forEach((item) => {
          if (item.ie_valor != "3" && !this.supervisao) {
            this.options.formasPagamento.push({
              value: item.ie_valor,
              text: item.ds_valor,
            });
          } else if (this.supervisao) {
            this.options.formasPagamento.push({
              value: item.ie_valor,
              text: item.ds_valor,
            });
          }
        });
        response.data.TIPO_SOLICITACAO?.valores.forEach((item) => {
          this.options.ie_tipo.push({
            value: item.ie_valor,
            label: item.ds_valor,
          });
        });
      });
    },
    getMotivosEstorno() {
      Caixas.findAllMotivoEstorno().then((response) => {
        if (response.status === 200) {
          this.options.motivosEstorno = response.data;
        } else if (response.status === 400) {
          if (response.data.detail) {
            if (typeof response.data.detail == "string")
              this.$toast.error(response.data.detail, { duration: 3000 });
            else
              response.data.detail.forEach((msg) =>
                this.$toast.error(msg, { duration: 3000 })
              );
          }
        }
      });
    },
    mostrarSelecionadosMovimento() {
      // let is_valid = true
      // let cod = ''
      // this.selecionados.forEach(item => {
      //   if(!item.ie_cadastro_manual) {
      //     is_valid = false
      //     cod = item.id
      //   return;
      // }
      // })
      // if(!is_valid) {
      //   this.$toast.error("O movimento " + cod + " não pode ser corrigido por ser um movimento TEF", {
      //     duration: 5000,
      //   });
      //   return
      // }
      this.dialogMovimento = true;
    },
    visualizarCaixa(cd_saldo_caixa) {
      if (this.supervisao)
        this.$router.push({
          path: `/fechamento-caixa/visualizar/${cd_saldo_caixa}/`,
        });
      else
        this.$router.push({
          path: `/historico-operacional/visualizar/${cd_saldo_caixa}/`,
        });
    },
    addNovoPagamento() {
      if (!this.movimento.cd_forma_pagamento) {
        this.$toast.error("Selecione um método de pagamento", {
          duration: 3000,
        });
        return;
      } else if (
        this.model.cd_forma_pagamento == 5 &&
        this.model.nr_credito_parcelas == null
      ) {
        this.$toast.error("Selecione as parcelas", { duration: 3000 });
        return;
      }
      if (this.movimento.nr_valor == 0 || isNaN(this.movimento.nr_valor)) {
        this.$toast.error("Insira um valor diferente de R$ 0,00", {
          duration: 3000,
        });
        return;
      }
      if ([5, 6].includes(this.movimento.cd_forma_pagamento)) {
        this.movimento.nr_final_cartao = (
          this.movimento.nr_final_cartao || ""
        ).replace(/\D/g, "");
        if (this.movimento.nr_final_cartao.length !== 4) {
          this.$toast.error("Informa os 4 últimos dígitos do cartão", {
            duration: 3000,
          });
          return;
        }
      }
      console.log(this.options.bandeiras);
      let mov = {
        cd_forma_pagamento: this.movimento.cd_forma_pagamento,
        cd_forma_pagamento_f: this.options.formasPagamentoMovimento.find(
          (elem) => elem.value == this.movimento.cd_forma_pagamento
        ).text,
        cd_pagante: this.model.cd_pagante,
        nr_credito_parcelas: this.movimento.nr_credito_parcelas,
        valor_total: this.model.ie_cirurgia
          ? this.total_cirurgia
          : this.options.operadoras.find(
              (item) => item.id === this.movimento.cd_operadora
            )?.ie_cobrar_cliente
          ? this.options.parcelas.find(
              (i) => i.value == this.movimento.nr_credito_parcelas
            )?.valor_total
          : this.options.parcelasCredishop.find(
              (i) => i.value == this.movimento.nr_credito_parcelas
            )?.valor_total,
        nr_valor: this.movimento.nr_valor,
        ie_registro_manual: true,
        ie_fluxo_alternativo: true,
        cd_operadora: this.movimento.cd_operadora,
        ie_bandeira_f: this.options.bandeiras[this.movimento.cd_operadora]?.find((item) => item.ie_valor === this.movimento.ie_bandeira)?.ds_valor,
        ie_bandeira: this.movimento.ie_bandeira,
        nr_final_cartao: this.movimento.nr_final_cartao,
        cd_transacao_financeiro: this.model.id,
        nr_valor_juros: this.movimento.nr_valor_juros,
        cd_autorizacao: this.movimento.cd_autorizacao
          ? this.movimento.cd_autorizacao
          : "",
        nr_pix_doc: this.movimento.nr_pix_doc ? this.movimento.nr_pix_doc : "",
        nr_valor_f: this.formatPrice(this.movimento.nr_valor),
        parcelas_f: this.options.operadoras?.find(
          (item) => item.id === this.movimento.cd_operadora
        )?.ie_cobrar_cliente
          ? this.options.parcelas.find(
              (i) => i.value == this.movimento.nr_credito_parcelas
            )?.text
          : this.options.parcelasCredishop.find(
              (i) => i.value == this.movimento.nr_credito_parcelas
            )?.text,
      };
      this.novosPagamentosList.push(mov);
      this.movimento = {
        cd_pagante: null,
        cd_atendimento: null,
        cd_forma_pagamento: null,
        nr_valor: 0,
        nr_credito_parcelas: null,
        ie_registro_manual: true,
        cd_operadora: null,
        ie_bandeira: null,
        nr_final_cartao: null,
        nr_inicio_cartao: null,
        cd_autorizacao: null,
        nr_pix_doc: null,
        ie_situacao: null,
        nr_valor_juros: 0,
        juros: null,
      };
      this.searchQuery = 0;
      this.dialogNovoPagamento = false;
    },
    addNovoComprovante(list) {
      let checkComprovante = list.aq_anexo != null;
      if (checkComprovante) {
        this.estorno.cd_anexos.push({
          aq_anexo: null,
          id: ++this.countComprovantesEstornos,
        });
      }
    },
    findJuros() {
      var dataSend = {
        cd_transacao_financeira: this.model.id,
        nr_valor: this.movimento.nr_valor,
      };
      Caixas.findJuros(dataSend).then((response) => {
        if (response.status === 200) {
          this.movimento = { ...this.movimento, juros: response.data.juros };
        } else if (response.status === 400) {
          if (response.data.detail) {
            if (typeof response.data.detail == "string")
              this.$toast.error(response.data.detail, { duration: 3000 });
            else
              response.data.detail.forEach((msg) =>
                this.$toast.error(msg, { duration: 3000 })
              );
          }
        }
      });
    },
    addNovoEstorno() {
      if (!this.movimento.cd_forma_pagamento) {
        this.$toast.error("Selecione um método de pagamento", {
          duration: 3000,
        });
        return;
      }
      if (this.movimento.nr_valor == 0 || isNaN(this.estorno.nr_valor)) {
        this.$toast.error("Insira um valor diferente de R$ 0,00", {
          duration: 3000,
        });
        return;
      }
      if (!this.movimento.ie_situacao && this.supervisao) {
        this.$toast.error("Insira a situação", {
          duration: 3000,
        });
        return;
      }
      let mov = {
        cd_forma_pagamento: this.movimento.cd_forma_pagamento,
        cd_forma_pagamento_f: this.options.formasPagamento.find(
          (elem) => elem.value == this.movimento.cd_forma_pagamento
        ).text,
        nr_valor_juros: this.juros,
        nr_valor_juros_f: this.formatPrice(this.juros),
        nr_valor: this.movimento.nr_valor,
        nr_valor_f: this.formatPrice(this.movimento.nr_valor),
        ie_situacao: this.movimento.ie_situacao,
      };
      this.novosEstornosList.push(mov);
      this.movimento = {};
      this.searchQuery = 0;
      this.dialogNovoEstorno = false;
    },
    mostrarNovoMovimento() {
      this.dialogNovoPagamento = true;
    },
    mostrarNovoEstorno() {
      this.movimento = {};
      this.searchQuery = 0;
      this.dialogNovoEstorno = true;
    },
    mostrarEstornosCancelados() {
      this.model.estornos.push(...this.estornosCancelados);
      this.exibeEstornosCancelados = true;
    },
    async finalizarCorrecaoMovimento() {
      this.clickEnabled = true
      this.waitingCorrecao = true;
      var dataSend = {};
      dataSend["cd_corrigir"] = this.selecionados.map((item) => item.id);
      dataSend["cd_correcao"] = this.novosPagamentosList.map((item) => {
        return {
          ds_motivo: this.ds_motivo_correcao,
          nr_valor: item.nr_valor,
          cd_forma_pagamento: item.cd_forma_pagamento,
          cd_transacao_cartao: {
            nr_credito_parcelas: item.nr_credito_parcelas,
            cd_operadora: item.cd_operadora,
            ie_bandeira: item.ie_bandeira,
            nr_final_cartao: item.nr_final_cartao,
            cd_autorizacao: item.cd_autorizacao,
            nr_pix_doc: item.nr_pix_doc,
            nr_valor_juros: item.nr_valor_juros,
          },
        };
      });
      Caixas.corrigirMovimento(this.model.id, dataSend).then((response) => {
        this.waitingCorrecao = false;
        if ([200, 201, 204].includes(response.status)) {
          this.$toast.success("Movimento coorrigido com sucesso.");
          this.dialogMovimento = false;
          this.$router.go();
          this.clickEnabled = false
        } else if (response.status == 400) {
          if (response.data.detail) {
            if (typeof response.data.detail == "string")
              this.$toast.error(response.data.detail, { duration: 3000 });
            else
              response.data.detail.forEach((msg) =>
                this.$toast.error(msg, { duration: 3000 })
              );
          }
          this.clickEnabled = false
        }
      });
    },
    esconderEstornosCancelados() {
      this.model.estornos = this.model.estornos.filter(
        (e) => e.ie_situacao != "CAN"
      );
      this.exibeEstornosCancelados = false;
    },
    openDialogEstornoTotal() {
      this.novosEstornosList = []
      this.ie_estorno_total = true,
      this.model.movimentos.forEach(item => {
        this.novosEstornosList.push({
          id: item.id,
          cd_forma_pagamento: this.options.formasPagamento.find((elem) => elem.text == item.ds_forma_pagamento).value,
          cd_forma_pagamento_f: item.ds_forma_pagamento,
          nr_valor: item.nr_valor,
          nr_valor_juros: item.nr_valor_juros,
          nr_valor_juros_f: this.formatPrice(item.nr_valor_juros),
          nr_valor_f: this.formatPrice(item.nr_valor)
        })
      })
      this.dialogEstornoTotal = true
    },
    openEstornoDialog(estorno, readonly) {
      this.novosEstornosList = [];
      if (!this.options.motivosEstorno.length) this.getMotivosEstorno();
      this.estornoReadonly = readonly;
      if (estorno) {
        estorno = Object.assign({}, estorno);
        const cd_guias = estorno.cd_guias.map((g) => g);
        estorno.cd_guias = this.model.guias.filter((guia) =>
          cd_guias.includes(guia.cd_guia_eletronica)
        );
        if (estorno.cd_anexos.length == 0) {
          estorno.cd_anexos = [
            {
              aq_anexo: "",
              id: this.countComprovantesEstornos,
              nm_anexo: "",
            },
          ];
        }
        estorno.cd_forma_pagamento_estorno.forEach((item) => {
          this.novosEstornosList.push({
            cd_forma_pagamento: item.cd_forma_pagamento,
            ie_situacao: item.ie_situacao,
            nm_caixa: item.nm_caixa,
            nr_valor: item.nr_valor,
            cd_forma_pagamento_f: this.options.formasPagamento.find(
              (elem) => elem.value == item.cd_forma_pagamento
            )?.text
              ? this.options.formasPagamento.find(
                  (elem) => elem.value == item.cd_forma_pagamento
                )?.text
              : item.nm_forma_pagamento,
            nr_valor_f: this.formatPrice(item.nr_valor),
          });
        });
      } else {
        if (!this.guiasEstorno.length) {
          this.$toast.error("Selecione a(s) guia(s).");
          return;
        }
        estorno = {
          cd_transacao: this.id,
          cd_guias: [...this.guiasEstorno],
          cd_forma_pagamento: this.options.formasPagamento[0]?.value,
          ds_motivo: "",
          cd_anexos: [
            {
              aq_anexo: "",
              id: this.countComprovantesEstornos,
              nm_anexo: "",
            },
          ],
          ds_observacao_pagamento: "",
        };
      }
      if (this.supervisao) estorno["ie_tipo"] = "E";
      this.estorno = estorno;
      this.estorno.nr_valor = 0;
      this.estorno.cd_guias.forEach(
        (guia) => (this.estorno.nr_valor += guia.valortotal)
      );
      this.dialogEstorno = true;
    },
    openEstornoAprovarConfirm(estorno) {
      if (estorno.cd_forma_pagamento_estorno.length == 0) {
        this.$toast.error("Insira as formas de devolução.");
        return;
      }
      this.estorno = estorno;
      this.dialogAprovarEstorno = true;
    },
    realizarPagamentosPendentes(id) {
      this.waitingAprovarEstorno = true;
      Caixas.realizarPagamentos(id).then((response) => {
        this.waitingSubmitEstorno = null;
        if ([200, 201, 204].includes(response.status)) {
          this.$toast.success("Estorno aprovado com sucesso!");
          var change = this.model.estornos.find((item) => item.id == id);
          change["ie_situacao"] = "APR";
          this.waitingImprimirEstornoId = true;
          this.waitingAprovarEstorno = false;
          this.dialogAprovarEstorno = false;
          this.$router.go();
        } else {
          this.waitingAprovarEstorno = false;
          if (response.data.detail) {
            if (typeof response.data.detail == "string")
              this.$toast.error(response.data.detail, { duration: 3000 });
            else {
              response.data.detail.forEach((msg) =>
                this.$toast.error(msg, { duration: 3000 })
              );
            }
          }
          this.waitingAprovarEstorno = false;
          this.dialogAprovarEstorno = false;
        }
      });
    },
    salvarEstorno() {
      let dataSend = {};
      dataSend.id = this.estorno.id;
      dataSend.cd_transacao = this.id;
      dataSend.cd_motivo_estorno = this.estorno.cd_motivo_estorno;
      dataSend.ds_motivo = this.estorno.ds_motivo;
      dataSend.ds_observacao_pagamento = this.estorno.ds_observacao_pagamento;
      dataSend.ie_tipo = this.estorno.ie_tipo;
      if(this.ie_estorno_total){
        dataSend.cd_guias = this.model.guias.map(
          (g) => g.cd_guia_eletronica
        )
      } else {
        dataSend.cd_guias = this.estorno.cd_guias.map(
        (g) => g.cd_guia_eletronica
      )
      }

      if (this.usuarioNivel >= 5 && this.estorno.ie_situacao !== "PEN") {
        dataSend.ie_situacao = this.estorno.ie_situacao;
      }

      if (!dataSend.cd_motivo_estorno) {
        this.$toast.error("Informe o motivo do estorno.");
        return;
      }
      if (this.novosEstornosList.lengtsh == 0) {
        this.$toast.error("Informe as forma(s) de pagamento do estorno.");
      }
      if (!this.supervisao && this.estorno.ie_tipo == "D")
        dataSend.cd_coordenador = this.estorno.cd_coordenador;

      dataSend.cd_forma_pagamento_estorno = this.novosEstornosList.map(
        (item) => {
          console.log(item)
          var aux = {};
          if (item.id) aux.id = item.id;
          aux.nr_valor = item.nr_valor,
          aux.cd_forma_pagamento = item.cd_forma_pagamento,
          aux.ie_situacao = item.ie_situacao;
          aux.nr_valor_juros = item.cd_forma_pagamento == 5 ? item.nr_valor_juros : 0;
          return aux;
        }
      );

      this.waitingSubmitEstorno = true;
      if (this.supervisao) {
        if (this.salvarAprovar.show) {
          Caixas.salvarEstorno(dataSend).then((response) => {
            this.waitingSubmitEstorno = false;
            if ([200, 201, 204].includes(response.status)) {
              this.$toast.success("Estorno salvo com sucesso.");
              this.realizarPagamentosPendentes(response.data.id);
              this.guiasEstorno = [];
              this.dialogEstorno = false;
              this.ie_estorno_total = false;
            } else if (response.status == 400) {
              if (response.data.detail) {
                if (typeof response.data.detail == "string")
                  this.$toast.error(response.data.detail, { duration: 3000 });
                else
                  response.data.detail.forEach((msg) =>
                    this.$toast.error(msg, { duration: 3000 })
                  );
              }
            }
          });
        } else {
          Caixas.salvarEstorno(dataSend).then((response) => {
            this.waitingSubmitEstorno = false;
            if ([200, 201, 204].includes(response.status)) {
              this.$toast.success("Estorno salvo com sucesso.");
              this.getTransacao();
              this.guiasEstorno = [];
              this.ie_estorno_total = false;
              this.dialogEstorno = false;
            } else if (response.status == 400) {
              if (response.data.detail) {
                if (typeof response.data.detail == "string")
                  this.$toast.error(response.data.detail, { duration: 3000 });
                else
                  response.data.detail.forEach((msg) =>
                    this.$toast.error(msg, { duration: 3000 })
                  );
              }
            }
          });
        }
      } else {
        Caixas.salvarEstornoOperacional(dataSend).then((response) => {
          this.waitingSubmitEstorno = false;
          if ([200, 201, 204].includes(response.status)) {

            this.$toast.success("Estorno salvo com sucesso.");
            this.getTransacao();
            this.guiasEstorno = [];
            this.ie_estorno_total = false;
            this.dialogEstorno = false;
          } else if (response.status == 400) {
            if (response.data.detail) {
              if (typeof response.data.detail == "string")
                this.$toast.error(response.data.detail, { duration: 3000 });
              else
                response.data.detail.forEach((msg) =>
                  this.$toast.error(msg, { duration: 3000 })
                );
            }
          }
        });
      }
    },
    openEstornoCancelarConfirm(estorno) {
      this.estorno = estorno;
      this.estornoCancelarConfirm = true;
    },
    cancelarEstorno(acao) {
      // let dataSend = { id: this.estorno.id, ie_situacao: "CAN" };

      this.waitingCancelarEstorno = true;
      Caixas[acao](this.estorno.id).then((response) => {
        this.waitingCancelarEstorno = false;
        if ([200, 201, 203, 204, 205, 206].includes(response.status)) {
          // this.estorno.ie_situacao = "CAN";
          this.estornosCancelados.push(this.estorno);
          if (!this.exibeEstornosCancelados) this.esconderEstornosCancelados();
          this.$toast.success("Solicitação de estorno cancelada com sucesso.");
          this.estornoCancelarConfirm = false;
          this.getTransacao();
        } else if ([400, 401, 403, 404, 500, 501].includes(response.status)) {
          if (response.data.detail) {
            if (typeof response.data.detail == "string")
              this.$toast.error(response.data.detail, { duration: 3000 });
            else
              response.data.detail.forEach((msg) =>
                this.$toast.error(msg, { duration: 3000 })
              );
          }
        }
      });
      // Caixas[acao](dataSend).then((response) => {
      //   this.waitingCancelarEstorno = false;
      //   if (response.status == 200) {
      //     this.estorno.ie_situacao = "CAN";
      //     this.estornosCancelados.push(this.estorno);
      //     if (!this.exibeEstornosCancelados) this.esconderEstornosCancelados();
      //     this.$toast.success("Solicitação de estorno cancelada com sucesso.");
      //     this.estornoCancelarConfirm = false;
      //   } else if (response.status == 400) {
      //     if (response.data.detail) {
      //       if (typeof response.data.detail == "string")
      //         this.$toast.error(response.data.detail, { duration: 3000 });
      //       else
      //         response.data.detail.forEach((msg) =>
      //           this.$toast.error(msg, { duration: 3000 })
      //         );
      //     }
      //   }
      // });
    },
    openDialogEstornosTEFPendentes() {
      this.dialogEstornosPendentes = true
      this.estornosTEFPendentes = []
      this.waitingEstornosPendentes = true
      TransacaoCartao.findAll({cd_transacao_financeira: this.id, ie_situacao: 'PA'})
      .then(response => {
        if(response.status == 200) {
          if(response.data.length) {
            response.data.forEach(item => {
              this.estornosTEFPendentes.push({
                id: item.id,
                cd_forma_pagamento: item.cd_forma_pagamento,
                nr_valor_acrescimo_f: this.formatPrice(+item.nr_valor_acrescimo),
                ie_situacao: item.ie_situacao,
                nm_bandeira: item.object_pagarme.intencoesVendas[0].pagamentosExternos[0].bandeira,
                cd_forma_pagamento_f: this.options.formasPagamento.find(
                  (elem) => elem.value == item.cd_forma_pagamento
                )?.text
                  ? this.options.formasPagamento.find(
                      (elem) => elem.value == item.cd_forma_pagamento
                    )?.text
                  : item.nm_forma_pagamento,
                nr_valor_f: this.formatPrice(item.nr_valor),
                amount_f: this.formatPrice(item.amount)

              })
            })
          }
          this.waitingEstornosPendentes = false
        }
      })
    },
    async removerFormadePagamento(elem) {
				this.waitingCancelamento = true
        try {
          await Caixas.removePagamentoTEF(elem.data.id)
          const cancelado = await this.loopConsultarVendaCancelada(elem.data.id)
          if(cancelado) {
            this.estornosTEFPendentes.find(item => item.id == elem.data.id).ie_situacao = 'CA'
            this.estornosTEFPendentes.find(item => item.id == elem.data.id)['comprovanteAdquirente'] = this.comprovantePagamentoImprimir
            this.imprimirComprovanteEstornoTotal(this.comprovanteAdquirente)
            this.$toast.success('Forma de pagamento removida', { duration: 3000 })
        }
        this.waitingCancelamento = false
        } catch (error) {
          this.$toast.error(error, { duration: 3000 })
        }
		},
    async loopConsultarVendaCancelada(cd_transacao_cartao) {
				return await new Promise((resolve) => {
				//De 1 em 1s, é feita uma consulta na API do medclub para verificar o andamento da venda
				const id = setInterval(async () => {
				let venda = await Caixas.pagamentoTEFSituacao(cd_transacao_cartao);
				/*
					CÓDIGOS RESPOSTAS:
					5	Pendente
					6	EmPagamento
					10	Creditado
					15	Expirado
					18	CancelamentoIniciado
					19	EmCancelamento
					20	Cancelado
					25	PagamentoRecusado

					Mais informações: https://devpaygo.readme.io/v4/docs/apis-transacional
				*/

				// Pagamento em Andamento
				/* if (!["5", "6"].includes(venda.data.status)) {
					clearInterval(id);
				} */

				// Pagamento Creditado
				if (venda.data.status == "20") {
          this.comprovantePagamentoImprimir = venda.data.object_pagarme?.intencoesVendas[0]?.pagamentosExternos[0]?.comprovanteAdquirente
					this.waitingPagamentoAprovado = false
					this.cd_transacao_cartao = cd_transacao_cartao;
					clearInterval(id)
					resolve(true);
				}

				// Pagamento Cancelado ou Recusado
				if (["15", "18", "10", "25"].includes(venda.data.status)) {
					this.waitingPagamentoAprovado = false
					this.$toast.error('Não foi possivel realizar o cancelamento', { duration: 5000 })
					clearInterval(id)
					resolve(false);
				}
				}, 1500);
				})
		},
    concluirCancelamentoTEF() {
      this.dialogEstornosPendentes = false
      this.$router.go()

    },
    anexarComprovanteEstorno() {
      const files = this.$refs.comprovanteEstorno.files;
      const comp = files.length && files[0];
      if (!comp) return;

      let dataSend = {
        aq_anexo: comp,
        cd_solicitacao_estorno: this.estorno.id,
      };
      this.waitingComprovanteEstorno = true;
      Caixas.anexarComprovanteEstorno(dataSend).then((response) => {
        this.waitingComprovanteEstorno = false;
        if (response.status === 200) {
          this.estorno.cd_anexos.push({
            aq_anexo: response.data.aq_anexo,
            nm_anexo: response.data.nm_anexo,
            id: ++this.countComprovantesEstornos,
          });
          //this.aq_deposito = response.data.aq_deposito;
          this.$toast.success("Comprovante anexado com sucesso.");
        } else if (response.data.detail) {
          this.toastResponseDetail(response.data.detail);
        } else {
          this.toastResponseDetail("Falha ao enviar comprovante.");
        }
      });
    },
    removerComprovanteEstorno(id, index) {
      this.waitingComprovanteEstorno = true;
      Caixas.removerComprovanteEstorno(id).then((response) => {
        this.waitingComprovanteEstorno = false;
        if (response.status === 200) {
          this.estorno.cd_anexos.splice(index, 1);
          this.estorno.nm_comprovante = "";
          this.aq_deposito = null;
          this.$refs.comprovanteEstorno.value = "";
          this.$toast.success("Comprovante removido com sucesso.");
        } else {
          this.toastResponseDetail(response.data.detail);
        }
      });
    },
    imprimirComprovanteEstorno(aq_anexo) {
      if (aq_anexo) window.open(aq_anexo);
      else this.$toast.error("Link para o arquivo não encontrado.");
    },
    alterarPagante() {
      if (!this.$refs.buscarNovoPagante.cd_pessoa_fisica) {
        this.$toast.error("Selecione um novo pagante", { duration: 3000 });
        return 0;
      }
      this.waitingAlterarPagante = true;
      Caixas.updateTransacaoPagante(
        this.model.id,
        this.$refs.buscarNovoPagante.cd_pessoa_fisica
      ).then((response) => {
        this.waitingAlterarPagante = false;
        if ([200, 201].includes(response.status)) {
          this.$toast.success("Pagante atualizado!", { duration: 3000 });
          this.dialogAlterarPagante = false;
          this.getTransacao();
        } else if (response.status == 400) {
          if (response.data.detail) {
            if (typeof response.data.detail == "string")
              this.$toast.error(response.data.detail, { duration: 3000 });
            else
              response.data.detail.forEach((msg) =>
                this.$toast.error(msg, { duration: 3000 })
              );
          }
        }
      });
    },
    fixNrValor() {
      if (this.movimento.ie_operacao == "C") {
        this.movimento.nr_valor = Math.abs(this.movimento.nr_valor);
      } else {
        this.movimento.nr_valor = -Math.abs(this.movimento.nr_valor);
      }
    },
    cancelarMovimento() {
      this.waitingApagar = true;
      let id = this.movimento.id;
      let dataSend = {
        ds_motivo: this.movimento.ds_motivo,
        dt_movimento: moment(this.movimento.dt_movimento).format("YYYY-MM-DD"),
      };
      Caixas.cancelarMovimento(id, dataSend).then((response) => {
        this.waitingApagar = false;
        if (response.status == 200) {
          this.dialogRemover = false;
          this.movimento = {};
          this.$toast.success("Movimento cancelado", { duration: 3000 });
          this.getTransacao();
        } else if (response.status == 400) {
          if (response.data.detail) {
            if (typeof response.data.detail == "string")
              this.$toast.error(response.data.detail, { duration: 3000 });
            else
              response.data.detail.forEach((msg) =>
                this.$toast.error(msg, { duration: 3000 })
              );
          }
        }
      });
    },
    emitirNF(val) {
      this.waitingNF = true;
      Caixas.emitirNF(this.model.id, val).then((response) => {
        this.waitingNF = false;
        if (response.status == 200) {
          this.$toast.info(
            `Nota fiscal atualizada: ${val ? "EMITIDA" : "NÃO EMITIDA"}`,
            { duration: 3000 }
          );
        }
      });
    },
    adicionar() {
      if (this.movimento.ds_motivo.length == 0) {
        this.$toast.error("Informe o motivo", { duration: 3000 });
        return 0;
      }
      if (this.movimento.ie_operacao == "C" && this.movimento.nr_valor <= 0) {
        this.$toast.error("Valor deve ser maior que 0", { duration: 3000 });
        return 0;
      }
      if (this.movimento.ie_operacao == "D" && this.movimento.nr_valor >= 0) {
        this.$toast.error("Valor deve ser menor que 0", { duration: 3000 });
        return 0;
      }
      let dataSend = Object.assign({}, this.movimento);

      dataSend.dt_movimento = moment(dataSend.dt_movimento).format(
        "YYYY-MM-DD"
      );

      this.waitingAdicionar = true;
      Caixas.incluirMovimento(this.model.id, dataSend).then((response) => {
        this.waitingAdicionar = false;
        if (response.status == 200) {
          this.dialogAdicionar = false;
          this.movimento = {};
          this.$toast.success("Movimento adicionado", { duration: 3000 });
          this.getTransacao();
        } else if (response.status == 400) {
          if (response.data.detail) {
            if (typeof response.data.detail == "string")
              this.$toast.error(response.data.detail, { duration: 3000 });
            else
              response.data.detail.forEach((msg) =>
                this.$toast.error(msg, { duration: 3000 })
              );
          }
        }
      });
    },
    getTransacao() {
      this.waiting = true;
      Caixas.findTransacao(this.id).then((response) => {
        this.waiting = false;

        if (response.status == 200) {
          this.model = response.data;
          this.model.dt_criado_f = moment(this.model.dt_criado).format(
            "DD/MM/YYYY HH:mm"
          );
          if (this.model.nr_cpf_pagante)
            this.model.nr_cpf_pagante_f = this.model.nr_cpf_pagante.replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})/,
              "$1.$2.$3-$4"
            );
          this.model.guias.forEach((guia) => {
            guia.cnpj_estabelecimento_f = guia.cnpj_estabelecimento.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5"
            );
            if (guia.ds_guia_situacao != "CANCELADA") {
              guia.id = guia.cd_guia_eletronica;
              this.valorTotalGuias += guia.valortotal;
            }
          });
          this.model.movimentos.forEach((guia) => {
            guia.dt_movimento_f = moment(guia.dt_movimento).format(
              "DD/MM/YYYY"
            );
          });
          this.model.nr_valor_f = this.formatPrice(this.model.nr_valor);
          this.model.vr_nota_guia_f = this.formatPrice(this.model.vr_nota_guia);
          this.$parent.$parent.habilitarEstorno = this.model.guias.length > 0;

          this.getEstornos(this.id);

                        this.$parent.$parent.buscarPacienteToggle = true
                        setTimeout(() => {
                            if (this.$parent.$parent.$refs.buscarPaciente) {
                                setTimeout(async () => {
                                    this.$parent.$parent.$refs.buscarPaciente.limpar()
                                    this.$parent.$parent.$refs.buscarPaciente.filtro.selected.value = this.model.nr_cpf_pagante_f
                                    await this.$parent.$parent.$refs.buscarPaciente.buscar()
                                    this.valor_saldoPagante = this.$parent.$parent.$refs.buscarPaciente.model.nr_saldo
                                })
                            }
                        })
                        //verificar se é possivel o estorno total
                        this.model.movimentos.forEach(guia => {
                          if(![1,5,6].includes(guia.cd_forma_pagamento) && this.supervisao) {
                            this.$emit("setEstornoTotalPossivel", true);
                          } else {
                            this.$emit("setEstornoTotalPossivel", false);
                          }
                        })
                        if(this.model.guias.find(item => item.ds_guia_situacao == 'CANCELADA'))
                            this.$emit("setEstornoTotalPossivel", true);
                        else this.$emit("setEstornoTotalPossivel", false);

                        
                    } else this.$router.go(-1)
                })
            },
            getEstornos(cdTransacao) {
                this.waiting = true
                Caixas.findAllEstorno({cd_transacao: cdTransacao, ie_transacao: true }).then(response => {
                    let estornos = response.data.results

                    estornos.forEach(estorno => {
                      if (estorno.nm_comprovante) estorno.nm_comprovante_f = this.formatFileName(estorno.nm_comprovante)
                      estorno.dt_criado_f = moment(estorno.dt_criado).format('DD/MM/YYYY HH:mm')
                      if (estorno.dt_aprovado) {
                          estorno.dt_aprovado_f = moment(estorno.dt_aprovado).format('DD/MM/YYYY HH:mm')
                      }
                      if (estorno.dt_atualizado) {
                          estorno.dt_atualizado_f = moment(estorno.dt_atualizado).format('DD/MM/YYYY HH:mm')
                      }
                      estorno.cd_anexos.push({
                        aq_anexo: estorno.aq_comprovante,
                        nm_anexo: estorno.aq_comprovante,
                        id: ++this.countComprovantesEstornos
                      })
                    })

                    if (this.exibeEstornosCancelados) this.model.estornos = estornos
                    else this.model.estornos = estornos.filter(e => e.ie_situacao !== 'CAN')
                    this.estornosCancelados = estornos.filter(e => e.ie_situacao === 'CAN')

        if (this.dialogEstorno) {
          let estorno = estornos.find((e) => e.id === this.estorno.id);
          this.openEstornoDialog(
            estorno,
            estorno.ie_situacao === "APR" ? true : false
          );
        }

        this.waiting = false;
      });
    },
    openConfirmRemoverComprovante() {
      this.dialogConfirm.msg = `Deseja mesmo REMOVER o comprovante anexado?`;
      this.dialogConfirm.visible = true;
    },
    // anexarComprovante() {
    //   const files = this.$refs.refComprovante.files;
    //   const comprovante = files.length && files[0];
    //   if (!comprovante) return;

    //   this.waitingComprovante = true;
    //   Caixas.anexarComprovanteMovimento(this.id, comprovante).then(
    //     (response) => {
    //       this.waitingComprovante = false;
    //       if (response.status === 200) {
    //         // this.nm_comprovante = this.formatFileName(
    //         //     comprovante.name
    //         // );
    //         this.aq_deposito = response.data.aq_deposito;
    //         this.$toast.success(
    //           "Comprovante de transferencia ANEXADO com sucesso."
    //         );
    //         location.reload();
    //       } else {
    //         this.toastResponseDetail(response.data.detail);
    //       }
    //     }
    //   );
    // },
    // removerComprovante() {
    //   this.waitingComprovante = true;
    //   Caixas.anexarComprovanteMovimento(this.id, null).then((response) => {
    //     this.waitingComprovante = false;
    //     if (response.status === 200) {
    //       this.sangria.nm_comprovante = "";
    //       this.aq_deposito = null;
    //       this.$refs.refComprovante.value = "";
    //       this.$toast.success(
    //         "Comprovante de transferencia REMOVIDO com sucesso."
    //       );
    //       location.reload();
    //     } else {
    //       this.toastResponseDetail(response.data.detail);
    //     }
    //   });
    // },
    abrirComprovante() {
      if (this.aq_deposito) window.open(this.aq_deposito);
      else this.$toast.error("Link para o arquivo não encontrado.");
    },

    openDialogCancelar() {
      //console.log(this.guiasEstorno)
      this.dialogCancelar.model.guias = this.guiasEstorno.map(
        (i) => i.cd_guia_eletronica
      );
      this.dialogCancelar.model.valorTotal = 0;
      this.guiasEstorno.forEach((item) => {
        this.dialogCancelar.model.valorTotal += item.valortotal;
      });
      this.dialogCancelar.display = true;
    },
    closeDialogCancelar() {
      this.dialogCancelar.display = false;
      this.dialogCancelar.model = {};
    },
    openDialogConfirmacao(creditar, ie_troca_prestador) {
      this.dialogCancelar.ie_troca_prestador = ie_troca_prestador;
      this.dialogCancelar.creditar = creditar;
      this.dialogConfirmacao = true;
    },
    cancelarGuia(id, creditar = false, ie_troca_prestador = false ) {
      this.waitingCancelarGuia = id;
      let params = {
        ie_troca_prestador: ie_troca_prestador
      }
      Caixas.cancelarGuia(id, creditar, params).then((response) => {
        this.waitingCancelarGuia = 0;
        if (response.status === 200) {
          this.$toast.success("Guia cancelada", { duration: 3000 });
          this.getTransacao();
          this.closeDialogCancelar();
        } else if (response.status == 400) {
          if (response.data.detail) {
            if (typeof response.data.detail == "string")
              this.$toast.error(response.data.detail, { duration: 3000 });
            else
              response.data.detail.forEach((msg) =>
                this.$toast.error(msg, { duration: 3000 })
              );
          }
        }
      });
    },
    cancelarGuias(cd_guias, creditar = false, ie_troca_prestador = false) {
      this.waitingCancelarGuia = cd_guias;
      Caixas.cancelarGuias({ cd_guias: cd_guias, ie_troca_prestador: ie_troca_prestador }, creditar).then(
        (response) => {
          this.waitingCancelarGuia = 0;
          if (response.status === 200) {
            this.$toast.success("Guias canceladas", { duration: 3000 });
            this.getTransacao();
            this.closeDialogCancelar();
            this.guiasEstorno = [];
          } else if (response.status == 400) {
            if (response.data.detail) {
              if (typeof response.data.detail == "string")
                this.$toast.error(response.data.detail, { duration: 3000 });
              else
                response.data.detail.forEach((msg) =>
                  this.$toast.error(msg, { duration: 3000 })
                );
            }
          }
        }
      );
    },
    imprimirComprovanteEstornoTotal(comprovante){
				var comp = `<div style="white-space: pre-wrap;font-family: Open Sans, Helvetica Neue, sans-serif;
					font-stretch: condensed;
					box-sizing: border-box;
					font-size: 0.7rem;
					line-height: 0.9rem;">${comprovante}<div>`;
				
				const blob = new Blob([comp], { type: "text/html; charset=utf-8" });
				let formData = new FormData();
				formData.append("file", blob);

				fetch("http://localhost:9990/imprimir", {
				method: "POST",
				body: formData,
				})
				.then(() => {
					this.$toast.success("Comprovante impresso", { duration: 3000 })
				})
				.catch(() => {
					this.$toast.error("Sem comunicação com a impressora", {duration: 3000});
				})
		},
    imprimirComprovante() {
      if (this.model) window.print();
    },
    imprimirGuias(termica = false) {
      let params = { html: termica };
      if (this.dialogOpcoesImpressao.cd_horario_agendamento) {
        params.cd_horario_agendamento =
          this.dialogOpcoesImpressao.cd_horario_agendamento;
      } else {
        params.cd_transacao_financeira = this.id;
      }

      this.dialogOpcoesImpressao.waiting = true;
      Caixas.imprimirGuias(params).then((response) => {
        if (response.status === 200) {
          if (termica) {
            if (!response.data.guias) return;
            this.enviarImpressoraTermica(response.data.guias).then((result) => {
              this.dialogOpcoesImpressao.waiting = false;
              if (result) {
                this.dialogOpcoesImpressao.visible = false;
                this.dialogOpcoesImpressao.cd_horario_agendamento = null;
              } else this.$toast.error("Sem conexão com impressora térmica.");
            });
          } else {
            let blob = new Blob([response.data], {
              type: "application/pdf; charset=utf-8",
            });
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL);

            this.dialogOpcoesImpressao.waiting = false;
            this.dialogOpcoesImpressao.visible = false;
            this.dialogOpcoesImpressao.cd_horario_agendamento = null;
          }
        } else {
          this.dialogOpcoesImpressao.waiting = false;
          if (response.data.detail) {
            if (typeof response.data.detail == "string") {
              this.$toast.error(response.data.detail, { duration: 3000 });
            } else
              response.data.detail.forEach((msg) =>
                this.$toast.error(msg, { duration: 3000 })
              );
          }
        }
      });
    },
    openDialogImprimirEstorno(id) {
      this.dialogImprimirEstorno.visible = true;
      this.dialogImprimirEstorno.id = id;
    },
    imprimirTermicaEstorno(termica = false) {
      this.dialogImprimirEstorno.waiting = true;
      Caixas.findEstornoPdf(this.dialogImprimirEstorno.id).then((response) => {
        this.dialogImprimirEstorno.waiting = false;
        if (response.status == 200) {
          if (termica) {
            let blob = new Blob([response.data], {
              type: "text/html; charset=utf-8",
            });
            var fd = new FormData();
            fd.append("file", blob);
            fetch("http://localhost:9990/imprimir", {
              method: "POST",
              body: fd,
            });
          } else {
            // let blob = new Blob([response.data], {
            //   type: "text/html; charset=utf-8",
            // });
            var tableStyle = `
                * {
                        font-family: Open Sans, Helvetica Neue, sans-serif;
                        box-sizing: border-box;
                    }
                @page {
                    size: a5;
                    margin: 20px;
                }
                `;
            var printWindow = window.open("", "_blank");
            printWindow.document.write(`
              <html>
                <head>
                    <title>Lista de exames</title>
                    <style media="print">'+ ${tableStyle} +'</style>
                </head>
                <body style='margin: 20px'>
                  ${response.data}
                </body>
            `);
            //var fileURL = URL.createObjectURL(printWindow);
            //printWindow.open(fileURL);
            setTimeout(() => {
              printWindow.print();
              printWindow.close();
            }, 300);
          }
          this.dialogImprimirEstorno.visible = false;
        } else this.$toast.error(response.data.detail, { duration: 3000 });
      });
    },
    metodoListener() {
      let found = this.formasPagamento.find(
        (item) => item.id == this.movimento.cd_forma_pagamento
      );
      this.exibeSaldo = found.ie_usa_debito_credito == true;
    },
    saldoPaciente() {
      Paciente.saldoPaciente(this.model.cd_pagante).then((response) => {
        if (response.status == 200) {
          this.valor_saldoPagante = response.data["saldo"];
        }
      });
    },
    formatFileName(name) {
      if (name.length < 30) return name;
      return name.substr(0, 20) + "..." + name.substr(name.length - 8);
    },
    openDialogImpressao() {
      this.dialogOpcoesImpressao.visible = true;
    },
    async enviarImpressoraTermica(guias) {
      const enviar = async (idx) => {
        let result;
        if (idx >= guias.length) return true;

        let guia = guias[idx];
        const blob = new Blob([guia], { type: "text/html; charset=utf-8" });
        let formData = new FormData();
        formData.append("file", blob);

        await fetch("http://localhost:9990/imprimir", {
          method: "POST",
          body: formData,
        })
          .then(async () => (result = await enviar(++idx)))
          .catch(() => (result = false));

        return result;
      };
      return await enviar(0);
    },
    atualizaIndicador(remove) {
      if (!remove) {
        if (!this.$refs.buscarIndicador.cd_pessoa_fisica) {
          this.$toast.error("Selecione um indicador", { duration: 3000 });
          return 0;
        }
      }
      this.waitingAtualizarIndicador = true;
      Caixas.updateIndicadorPagante(
        this.model.id,
        remove ? null : this.$refs.buscarIndicador.cd_pessoa_fisica
      ).then((response) => {
        this.waitingAtualizarIndicador = false;
        if ([200, 201].includes(response.status)) {
          this.$toast.success("Indicador atualizado!", { duration: 3000 });
          this.dialogAtualizarIndicador = false;
          this.getTransacao();
        } else if (response.status == 400) {
          if (response.data.detail) {
            if (typeof response.data.detail == "string")
              this.$toast.error(response.data.detail, { duration: 3000 });
            else
              response.data.detail.forEach((msg) =>
                this.$toast.error(msg, { duration: 3000 })
              );
          }
        }
      });
    },
  },
};
</script>
